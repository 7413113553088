import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import ToggleButton from '@components/Base/ToggleButton';
import messages from '@components/Flows/ConfigPanels/messages';
import PanelHeader from '@components/Flows/ConfigPanels/PanelHeader';
import configCardMessages from '@components/Flows/ConfigureCard/messages';
const OffboardingPanel = (props) => {
    const { config: { feedback }, isUpdated, setDefaultPanelType, saveConfig, updateFlowConfigStatus, createFlowConfigStatus, updateFeedbackChange, } = props;
    const handleFeedbackChange = (isFeedback) => updateFeedbackChange({ feedback: isFeedback });
    return (_jsxs(_Fragment, { children: [_jsx(PanelHeader, { label: _jsx(FormattedMessage, Object.assign({}, configCardMessages.offboarding_label)), description: _jsx(FormattedMessage, Object.assign({}, configCardMessages.offboarding_description)), setDefaultPanelType: setDefaultPanelType }), _jsx("div", { className: "flex flex-col h-full p-5", children: _jsxs("div", { className: "flex items-center gap-3 px-4 py-3 bg-white rounded-md shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)]", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, configCardMessages.feedback)) }), _jsx("span", { className: "text-xs text-gray-600", children: _jsx(FormattedMessage, Object.assign({}, configCardMessages.feedback_description)) })] }), _jsx(ToggleButton, { "data-testid": "feedback_switch", checked: feedback, onClick: handleFeedbackChange, disabled: false })] }) }), _jsx("div", { className: "w-full px-5 py-4 mt-auto border-t border-gray-200 ", children: _jsx(Button, { isLoading: updateFlowConfigStatus.isConfigUpdateLoading ||
                        createFlowConfigStatus.isLoading, "data-testid": "save_button", className: "w-full text-sm", disabled: !isUpdated, onClick: saveConfig, tabIndex: 0, children: _jsx(FormattedMessage, Object.assign({}, messages.save_changes)) }) })] }));
};
export default OffboardingPanel;
