import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: '',
    data: [],
};
const userSlice = createSlice({
    name: 'userList',
    initialState: initialState,
    reducers: {
        getUserListAction: (state) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = '';
        },
        getUserListSuccessAction: (state, { payload }) => {
            state.isLoading = false;
            state.data = payload;
            state.isSuccess = true;
            state.isError = false;
        },
        getUserListErrorAction: (state, { payload: error }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = error;
        },
    },
});
export const { getUserListAction, getUserListSuccessAction, getUserListErrorAction, } = userSlice.actions;
export default userSlice.reducer;
