var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { eventChannel } from 'redux-saga';
import { authenticateToken } from '@containers/auth/login/api';
import Auth from '@utils/auth';
import { app, auth } from '@utils/firebase';
export const createEventChannel = () => eventChannel((emitter) => {
    const unsubscribe = auth.onAuthStateChanged((user) => emitter({ user }));
    return () => unsubscribe();
});
export function init() {
    return __awaiter(this, void 0, void 0, function* () {
        const search = new URLSearchParams(window.location.search);
        if (search.get('accessToken')) {
            yield Auth.logout();
            try {
                yield authenticateToken({
                    access_token: search.get('accessToken') || '',
                });
            }
            catch (error) {
                console.error('Authentication failed', error);
            }
            search.delete('accessToken');
            window.location.search = search.toString();
        }
        return app;
    });
}
