export const flowStatus = {
    ARCHIVED: 'ARCHIVED',
    DRAFT: 'DRAFT',
    PUBLISHED: 'PUBLISHED',
};
export const flowStatusList = [
    { label: 'Archived', value: flowStatus.ARCHIVED },
    { label: 'Draft', value: flowStatus.DRAFT },
    { label: 'Published', value: flowStatus.PUBLISHED },
];
