var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Auth from '@utils/auth';
import { getDefaultContext } from './helpers';
import { processQuery } from './processQuery';
export const makeRequest = (_a) => __awaiter(void 0, [_a], void 0, function* ({ payload, queryString, context = {}, spreadPayload, }) {
    const apolloClient = yield Auth.client();
    const defaultContext = getDefaultContext(Auth.graphToken || '');
    return apolloClient.mutate({
        mutation: queryString,
        variables: spreadPayload ? Object.assign({}, payload) : { payload },
        context: Object.assign(Object.assign({}, defaultContext), context),
    });
});
const postData = (_b) => __awaiter(void 0, [_b], void 0, function* ({ queryString, payload, spreadPayload = false, context = {}, }) {
    if (!queryString) {
        throw Error('queryString not provided');
    }
    const graphQuery = processQuery(queryString);
    if ('errorMessage' in graphQuery &&
        (graphQuery === null || graphQuery === void 0 ? void 0 : graphQuery.errorMessage) === 'Invalid Query') {
        throw graphQuery;
    }
    const response = yield makeRequest({
        payload,
        queryString: graphQuery,
        spreadPayload,
        context,
    });
    return response;
});
export default postData;
