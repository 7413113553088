import { defineMessages } from 'react-intl';
export const scope = 'app.components.account_settings';
export default defineMessages({
    header: {
        id: `${scope}.header`,
        defaultMessage: 'Account Settings',
    },
    profile: {
        id: `${scope}.profile`,
        defaultMessage: 'Profile',
    },
    notification_preferences: {
        id: `${scope}.notification_preferences`,
        defaultMessage: 'Notification Preferences',
    },
    change_password: {
        id: `${scope}.change_password`,
        defaultMessage: 'Change Password',
    },
    language_settings: {
        id: `${scope}.language_settings`,
        defaultMessage: 'Language Settings',
    },
    timezone: {
        id: `${scope}.timezone`,
        defaultMessage: 'Timezone',
    },
    new_feature: {
        id: `${scope}.new_feature`,
        defaultMessage: 'Get Ready for an Exciting New Feature - Coming Soon!',
    },
});
