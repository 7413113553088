export const projectUser = {
    id: '1',
    name: 'John',
    lastname: 'Doe',
    email: 'john.doe@example.com',
    active: true,
    createdAt: new Date(),
    updatedAt: new Date(),
};
export var ROLE;
(function (ROLE) {
    ROLE["ADMIN"] = "ADMIN";
    ROLE["RECRUITER"] = "RECRUITER";
    ROLE["MASTER_RECRUITER"] = "MASTER_RECRUITER";
    ROLE["CONTENT_MANAGER"] = "CONTENT_MANAGER";
    ROLE["EVALUATOR"] = "EVALUATOR";
    ROLE["COLLABORATOR"] = "COLLABORATOR";
    ROLE["HIRING_MANAGER"] = "HIRING_MANAGER";
    ROLE["MASTER_CONTENT_MANAGER"] = "MASTER_CONTENT_MANAGER";
    ROLE["TENANT_ADMIN"] = "TENANT_ADMIN";
})(ROLE || (ROLE = {}));
