import { defineMessages } from 'react-intl';
import { commonScope } from '@utils/messages';
export const scope = 'app.containers.flow_details';
export default defineMessages({
    flow_publish: {
        id: `${scope}.flow_publish`,
        defaultMessage: 'Flow has been published!',
    },
    flow_publish_description: {
        id: `${scope}.flow_publish_description`,
        defaultMessage: 'Your flow has been successfully published and is now ready to be added to exams.',
    },
    changes_saved: {
        id: `${scope}.changes_saved`,
        defaultMessage: 'Changes saved',
    },
    changes_saved_description: {
        id: `${scope}.changes_saved_description`,
        defaultMessage: 'The changes have been saved successfully.',
    },
    changes_failed: {
        id: `${scope}.changes_failed`,
        defaultMessage: 'Changes failed',
    },
    changes_failed_description: {
        id: `${scope}.changes_failed_description`,
        defaultMessage: 'The changes failed to save.',
    },
    flow_name_updated: {
        id: `${scope}.flow_name_updated`,
        defaultMessage: 'Flow name updated',
    },
    flow_name_updated_description: {
        id: `${scope}.flow_name_updated_description`,
        defaultMessage: 'Flow name has been updated successfully.',
    },
    flow_name_update_failed: {
        id: `${scope}.flow_name_update_failed`,
        defaultMessage: 'Update failed',
    },
    flow_name_update_failed_description: {
        id: `${scope}.flow_name_update_failed_description`,
        defaultMessage: 'The changes failed to save.',
    },
    something_went_wrong: {
        id: `${commonScope}.something_went_wrong`,
        defaultMessage: 'Something went wrong',
    },
});
