import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import Avatar from '@components/Base/Avatar';
import getDisplayName from '@utils/getDisplayName';
const sizes = {
    xs: {
        wrapper: 'space-x-2',
        title: 'text-xs',
        description: 'text-xs',
    },
    sm: {
        wrapper: 'space-x-3',
        title: 'text-sm',
        description: 'text-xs',
    },
    md: {
        wrapper: 'space-x-4',
        title: 'text-base',
        description: 'text-sm',
    },
    lg: {
        wrapper: 'space-x-5',
        title: 'text-lg',
        description: 'text-base',
    },
};
const User = (props) => {
    const { data: userData, showEmail, size = 'sm', hideImage, avatarProps, additionalContent, } = props;
    const fullName = useMemo(() => getDisplayName(userData), [userData]);
    if (!userData) {
        return _jsx("div", { children: "-" });
    }
    return (_jsxs("div", { className: classNames('flex flex-1 items-center', sizes[size].wrapper), children: [!hideImage && _jsx(Avatar, Object.assign({ size: "lg", data: userData }, avatarProps)), _jsxs("div", { className: classNames('flex flex-1 flex-col max-w-max'), children: [_jsx("span", { className: classNames(get(sizes[size], 'title'), 'font-normal text-sm text-gray-900'), children: fullName }), additionalContent && (_jsx("div", { className: classNames(`text-gray-700`, sizes[size].description), children: additionalContent })), showEmail && (_jsx("div", { className: classNames(typeof showEmail === 'string' && 'mt-1', sizes[size].description), children: userData.email || userData.username }))] })] }));
};
export default User;
