import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import globalErrorListener from '@containers/app/middleware';
import rootReducer from '@utils/root-reducers';
import rootSaga from '@utils/root-sagas';
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware, globalErrorListener];
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [
                'app/initComplete',
                'app/initCompleteAction',
                'app/setGlobalErrorAction',
                'app/uploadFileAction',
            ],
            ignoredPaths: ['app.error', 'app.user.metadata'],
        },
    }).concat(middleware),
});
sagaMiddleware.run(rootSaga);
