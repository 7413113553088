var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { toast } from 'react-toastify';
import Content from './NotificationContent/Content';
export const Notification = (props) => {
    const { children, variant, message, actions, closeToast } = props;
    return (_jsx(_Fragment, { children: children || (_jsx(Content, { title: message.title, summary: message.summary, onClose: closeToast, actions: actions, variant: variant })) }));
};
export const triggerToast = (arg) => {
    const { setting = {} } = arg, props = __rest(arg, ["setting"]);
    const defaultSettings = Object.assign({ theme: 'light', icon: false, hideProgressBar: true, autoClose: 5000, position: 'bottom-right' }, setting);
    toast(_jsx(Notification, Object.assign({}, props)), defaultSettings);
};
