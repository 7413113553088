var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { get } from 'lodash';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { init, createEventChannel } from '@containers/app/api';
import { getFileUrlAction, initClientAction, initCompleteAction, selectCurrentUser, setCurrentUserAction, setGlobalErrorAction, signoutAction, updateUserAction, uploadProfilePhotoAction, setTimezonesSuccessAction, } from '@containers/app/slice';
import { logout } from '@containers/auth/login/api';
import { redirectToAdminLogout } from '@containers/auth/login/helpers';
import Auth from '@utils/auth';
import { axiosPostData } from '@utils/axiosPostData';
import { getDisplayRole } from '@utils/data/roles';
import fetchData from '@utils/fetchData';
import { getHasuraHeaders } from '@utils/helpers';
import postData from '@utils/postData';
import { FETCH_FILE_URL, UPLOAD_FILE, GET_USER_PROFILE_DETAILS, UPDATE_USER, } from './queries';
// eslint-disable-next-line
export function* getUserDetailsSaga() {
    try {
        const data = yield call(fetchData, {
            queryString: GET_USER_PROFILE_DETAILS,
            queryKey: 'auth_user_me',
            queryVariables: {},
            forceRefresh: true,
        });
        const timeZoneList = yield call(fetchData, {
            queryString: GET_USER_PROFILE_DETAILS,
            queryKey: 'md_timezone',
            queryVariables: {},
        });
        if (data && data.id) {
            yield put(setCurrentUserAction(data));
            yield call(updateStoredData, data);
            yield put(setTimezonesSuccessAction(timeZoneList));
        }
    }
    catch (error) {
        yield put(setGlobalErrorAction(error));
    }
}
export function updateStoredData(data) {
    const userDetails = {
        email: data.email,
        name: data.name,
        tenant: { id: data.tenant.id, name: data.tenant.name },
        username: data.username,
    };
    const storedData = JSON.parse(localStorage.getItem('tv.pc') || '{}');
    localStorage.setItem('tv.pc', JSON.stringify(Object.assign(Object.assign({}, storedData), { userDetails })));
}
export function handleUser(_a) {
    return __awaiter(this, arguments, void 0, function* ({ user }) {
        let client = {};
        let newUser = {};
        if (user) {
            const graphToken = yield user.getIdToken();
            client = yield Auth.createClient({
                graphToken,
                errorCallback: () => { },
                // role: get(th, '0'),
            });
            const parsedHeaders = getHasuraHeaders(graphToken);
            const parsedTenantId = get(parsedHeaders, 'X-Hasura-Tenant-Id');
            const parsedUserId = get(parsedHeaders, 'X-Hasura-User-Id');
            const roles = get(parsedHeaders, 'X-Hasura-Allowed-Roles');
            newUser = {
                displayName: user.displayName,
                roles,
                currentRole: getDisplayRole(roles),
                accountName: '',
                userName: user.username,
                organization: user.tenantId || (parsedTenantId && Number(parsedTenantId)),
                isLoggedIn: !user.isAnonymous,
                email: user.email,
                uid: user.uid,
                id: user.id || (parsedUserId && Number(parsedUserId)),
            };
        }
        return {
            client,
            user: newUser,
        };
    });
}
export function* fetchFileUrlSaga({ payload, }) {
    const { queryVariables, callback } = payload;
    try {
        const response = yield call(fetchData, {
            queryString: FETCH_FILE_URL,
            queryVariables,
            queryKey: 'file_download_file',
        });
        if (response && response.resource_url && callback.onSuccess) {
            yield call(callback.onSuccess, response);
        }
    }
    catch (error) {
        yield put(setGlobalErrorAction(error));
    }
}
export function* updateUserSaga({ payload, }) {
    const { body, callback } = payload;
    try {
        const response = yield call(postData, {
            queryString: UPDATE_USER,
            payload: body,
        });
        if (response && response.data) {
            yield call(getUserDetailsSaga);
            yield call(callback.onSuccess);
        }
    }
    catch (error) {
        yield call(callback.onError, error);
        yield put(setGlobalErrorAction(error));
    }
}
export function* uploadFileSaga({ payload, }) {
    const { body, callback } = payload;
    try {
        const uploadFilePayload = {
            file_type_id: body.additionalParameter.file_type_id,
            original_name: body.file.name,
            owner_id: body.additionalParameter.owner_id,
            extension: body.file.name.split('.').pop(),
        };
        const response = yield call(postData, {
            queryString: UPLOAD_FILE,
            payload: uploadFilePayload,
            spreadPayload: true,
        });
        if (response && response.data.file_upload_file) {
            const fileUploadData = response.data.file_upload_file.data;
            const URL = response.data.file_upload_file.data.url;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { url } = fileUploadData, newFileUploadData = __rest(fileUploadData, ["url"]);
            const formData = new FormData();
            Object.entries(newFileUploadData).forEach(([key, value]) => value && formData.append(key, value));
            formData.append('file', body.file);
            yield call(axiosPostData, URL, formData);
            if (callback.onSuccess && response.data) {
                yield call(callback.onSuccess, response.data.file_upload_file.id);
            }
        }
    }
    catch (error) {
        yield call(callback.onError, error);
        yield put(setGlobalErrorAction(error));
    }
}
// eslint-disable-next-line
export function* signoutSaga() {
    try {
        const currentUser = yield select(selectCurrentUser);
        yield call(logout);
        redirectToAdminLogout(currentUser.tenant.id, currentUser.username, history, true);
    }
    catch (error) {
        yield put(setGlobalErrorAction(error));
    }
}
// eslint-disable-next-line
export function* initSaga() {
    var _a;
    try {
        yield call(init);
        const channel = yield createEventChannel();
        while (true) {
            const data = yield take(channel);
            const { user } = yield call(handleUser, { user: data.user });
            yield put(initCompleteAction({
                user: Object.assign(Object.assign({}, user), { metadata: (_a = data === null || data === void 0 ? void 0 : data.user) === null || _a === void 0 ? void 0 : _a.metadata }),
            }));
            if (user && (user.email || (user === null || user === void 0 ? void 0 : user.uid))) {
                yield call(getUserDetailsSaga);
            }
        }
    }
    catch (error) {
        yield put(setGlobalErrorAction(error));
    }
}
export function* watchGetApp() {
    yield takeLatest(initClientAction.type, initSaga);
}
export function* watchUpdateUser() {
    yield takeLatest(updateUserAction.type, updateUserSaga);
}
export function* watchSignout() {
    yield takeLatest(signoutAction.type, signoutSaga);
}
export function* watchUploadFile() {
    yield takeLatest(uploadProfilePhotoAction.type, uploadFileSaga);
}
export function* watchGetFileUrl() {
    yield takeLatest(getFileUrlAction.type, fetchFileUrlSaga);
}
export function* appSagas() {
    yield all([
        watchGetApp(),
        watchUpdateUser(),
        watchSignout(),
        watchUploadFile(),
        watchGetFileUrl(),
    ]);
}
