import { defineMessages } from 'react-intl';
export const scope = 'app.components.sessions.sessions_list';
export default defineMessages({
    all_sessions: {
        id: `${scope}.all_sessions`,
        defaultMessage: 'All Sessions',
    },
    requests: {
        id: `${scope}.requests`,
        defaultMessage: 'Requests',
    },
});
