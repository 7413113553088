import alertIcon from '../../../../images/svgs/alert.svg';
import cautionIcon from '../../../../images/svgs/caution.svg';
import infoIcon from '../../../../images/svgs/info.svg';
import successIcon from '../../../../images/svgs/success.svg';
export const dataMap = {
    success: {
        icon: successIcon,
        color: 'text-green-700',
        borderColor: 'border-green-100',
        altText: 'success icon',
    },
    danger: {
        icon: alertIcon,
        color: 'text-red-700',
        borderColor: 'border-red-50',
        altText: 'alert icon',
    },
    warning: {
        icon: cautionIcon,
        color: 'text-yellow-500',
        borderColor: 'border-yellow-100',
        altText: 'caution icon',
    },
    info: {
        icon: infoIcon,
        color: 'text-blue-700',
        borderColor: 'border-blue-100',
        altText: 'info icon',
    },
    default: {
        icon: infoIcon,
        color: 'text-blue-700',
        borderColor: 'border-blue-100',
        altText: 'info icon',
    },
};
