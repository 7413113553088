// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@images/bg-landing.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-landing-overlay {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  background-size: cover;
  background-position: 0 -120px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Projects/ProjectLanding/styles.css"],"names":[],"mappings":"AAAA;EACE,6DAAmD;EACnD,sBAAsB;EACtB,6BAA6B;AAC/B","sourcesContent":[".bg-landing-overlay {\n  background: url('@images/bg-landing.svg') no-repeat;\n  background-size: cover;\n  background-position: 0 -120px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
