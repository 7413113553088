import { get } from 'lodash';
export const getAttendeeOnboardingConfig = (config) => {
    return {
        browser_chrome_desktop: get(config, 'browser_chrome_desktop', false),
        browser_chrome_mobile: get(config, 'browser_chrome_mobile', false),
        browser_edge_desktop: get(config, 'browser_edge_desktop', false),
        browser_edge_mobile: get(config, 'browser_edge_mobile', false),
        browser_firefox_desktop: get(config, 'browser_firefox_desktop', false),
        browser_firefox_mobile: get(config, 'browser_firefox_mobile', false),
        browser_safari_mobile: get(config, 'browser_safari_mobile', false),
        browser_sb_desktop: get(config, 'browser_sb_desktop', false),
        sb_content_protection: get(config, 'sb_content_protection', false),
        sb_blacklisted_apps: get(config, 'sb_blacklisted_apps', {}),
        sb_kiosk_mode: get(config, 'sb_kiosk_mode', false),
        screen_feed: get(config, 'screen_feed', ''),
        primary_camera_feed: get(config, 'primary_camera_feed', ''),
        secondary_camera_feed: get(config, 'secondary_camera_feed', ''),
        environment_test: get(config, 'environment_test', false),
        face: get(config, 'face', ''),
        photo_id: get(config, 'photo_id', ''),
    };
};
