import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import ToggleButton from '@components/Base/ToggleButton';
import messages from '@components/Flows/ConfigPanels/messages';
import configCardMessages from '@components/Flows/ConfigureCard/messages';
import { VERIFICATION_TYPE } from '@utils/data/enums';
const IdentityVerification = (props) => {
    const { config, updateConfig } = props;
    const handleUpdateConfig = (updatedConfig) => {
        updateConfig(Object.assign({}, updatedConfig));
    };
    return (_jsx("div", { className: "mx-4", children: _jsxs("div", { className: "flex flex-col gap-4 mt-1 pb-1", children: [_jsxs("div", { className: "px-4 py-3 bg-white rounded-md shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)]", children: [_jsxs("div", { className: "flex items-center gap-3", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, configCardMessages.face_capture)) }), _jsx("span", { className: "text-xs text-gray-600 font-roboto", children: _jsx(FormattedMessage, Object.assign({}, messages.face_capture_description)) })] }), _jsx(ToggleButton, { "data-testid": "face_switch", checked: config.face !== VERIFICATION_TYPE.DISABLE, onClick: (checked) => {
                                        handleUpdateConfig({
                                            face: checked
                                                ? VERIFICATION_TYPE.CAPTURE
                                                : VERIFICATION_TYPE.DISABLE,
                                        });
                                    }, disabled: false })] }), config.face !== VERIFICATION_TYPE.DISABLE && (_jsxs("div", { className: "flex items-center gap-3 mt-2.5", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.face_capture_verify)) }), _jsx("span", { className: "text-xs text-gray-600 font-roboto", children: _jsx(FormattedMessage, Object.assign({}, messages.face_capture_verify_description)) })] }), _jsx(ToggleButton, { "data-testid": "face_verification_switch", checked: config.face === VERIFICATION_TYPE.VERIFY, onClick: (checked) => {
                                        handleUpdateConfig({
                                            face: checked
                                                ? VERIFICATION_TYPE.VERIFY
                                                : VERIFICATION_TYPE.CAPTURE,
                                        });
                                    }, disabled: false })] }))] }), _jsxs("div", { className: "px-4 py-3 bg-white rounded-md shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)]", children: [_jsxs("div", { className: "flex items-center gap-3", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, configCardMessages.photo_id_capture)) }), _jsx("span", { className: "text-xs text-gray-600 font-roboto", children: _jsx(FormattedMessage, Object.assign({}, messages.photo_id_description)) })] }), _jsx(ToggleButton, { "data-testid": "photo_id_switch", checked: config.photo_id !== VERIFICATION_TYPE.DISABLE, onClick: (checked) => {
                                        handleUpdateConfig({
                                            photo_id: checked
                                                ? VERIFICATION_TYPE.CAPTURE
                                                : VERIFICATION_TYPE.DISABLE,
                                        });
                                    }, disabled: false })] }), config.photo_id !== VERIFICATION_TYPE.DISABLE && (_jsxs("div", { className: "flex items-center gap-3 mt-2.5", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.face_capture_verify)) }), _jsx("span", { className: "text-xs text-gray-600 font-roboto", children: _jsx(FormattedMessage, Object.assign({}, messages.photo_id_capture_verify_description)) })] }), _jsx(ToggleButton, { "data-testid": "photo_id_verification_switch", checked: config.photo_id === VERIFICATION_TYPE.VERIFY, onClick: (checked) => {
                                        handleUpdateConfig({
                                            photo_id: checked
                                                ? VERIFICATION_TYPE.VERIFY
                                                : VERIFICATION_TYPE.CAPTURE,
                                        });
                                    }, disabled: false })] }))] })] }) }));
};
export default IdentityVerification;
