import { defineMessages } from 'react-intl';
export const scope = 'app.components.flows.no_flow_page';
export default defineMessages({
    no_flow_created: {
        id: `${scope}.no_flow_created`,
        defaultMessage: 'No Flows Created Yet',
    },
    no_flow_created_description: {
        id: `${scope}.no_flow_created_description`,
        defaultMessage: 'There are no flow configurations created at the moment. You can create one now.',
    },
});
