import { defineMessages } from 'react-intl';
export const scope = 'app.components.signout_modal';
export default defineMessages({
    sign_out: {
        id: `${scope}.sign_out`,
        defaultMessage: 'Sign Out',
    },
    sign_out_warning: {
        id: `${scope}.sign_out_warning`,
        defaultMessage: 'Are you sure you want to sign out?',
    },
    go_back: {
        id: `${scope}.go_back`,
        defaultMessage: 'Go back',
    },
    confirm: {
        id: `${scope}.save_changes`,
        defaultMessage: 'Yes, Sign Out',
    },
});
