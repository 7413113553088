import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';
const SearchInput = ({ placeholderMessageId, handleChange, initialValue = '', }) => {
    const intl = useIntl();
    const [value, setValue] = useState(initialValue || '');
    const onChangeHandler = useCallback((searchText) => {
        if (handleChange) {
            handleChange(searchText);
        }
    }, [handleChange, value]);
    const debounceCallback = useCallback(debounce((searchText) => {
        onChangeHandler(searchText);
    }, 500), [onChangeHandler]);
    useEffect(() => {
        debounceCallback(value);
        return debounceCallback.cancel;
    }, [value, debounceCallback]);
    return (_jsxs("div", { className: "relative flex mt-1 rounded-md", children: [_jsx("div", { className: classNames('absolute inset-y-0 left-0 pl-3 flex items-center'), children: _jsx(MagnifyingGlassIcon, { "aria-hidden": "true", className: "w-5 h-5 text-gray-900" }) }), _jsx("input", { "aria-label": "Search", type: "text", value: value, onChange: (e) => setValue(e.target.value), placeholder: intl.formatMessage({ id: placeholderMessageId }), className: "w-full px-10 py-2 text-sm font-normal text-gray-500 border border-gray-200 rounded-md font-roboto focus:outline-none focus:ring-0 focus:border-gray-200" })] }));
};
export default SearchInput;
