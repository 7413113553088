import { defineMessages } from 'react-intl';
import { commonScope } from '@utils/messages';
export const scope = 'app.component.flow_header.';
export default defineMessages({
    unsaved_changes: {
        id: `${scope}.unsaved_changes`,
        defaultMessage: 'Unsaved changes',
    },
    unsaved_changes_warning: {
        id: `${scope}.unsaved_changes_warning`,
        defaultMessage: 'Are you sure you want to discard changes?',
    },
    keep_editing: {
        id: `${scope}.keep_editing`,
        defaultMessage: 'Keep editing',
    },
    discard: {
        id: `${commonScope}.discard`,
        defaultMessage: 'Discard',
    },
});
