import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { faPlus, faCamera } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import { catchError, handleEnterKeyPress, showNotification, } from '@utils/helpers';
import { fileTypes, handleImageUpload } from '@utils/imageUploadHelpers';
import withUserActions from 'app/hoc/withUserActions';
import messages from './messages';
export function ProfilePic(props) {
    const { setEditProfilePic, supportedFileTypes = fileTypes, data, onUpdateProfilePhoto, onUpdateUserAction, } = props;
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const handleDivClick = () => {
        fileInputRef.current && fileInputRef.current.click();
    };
    const storeSelectedFile = (file) => {
        setSelectedFile(file);
    };
    const fileUploadError = (error) => {
        catchError(error, false);
    };
    const updateProfilePic = (newProfilePicId) => {
        if (!onUpdateUserAction)
            return;
        setIsLoading(true);
        onUpdateUserAction({
            body: {
                profile_pic_file_id: newProfilePicId,
            },
            callback: {
                onSuccess: () => {
                    setIsLoading(false);
                    showNotification({
                        variant: 'success',
                        title: _jsx(FormattedMessage, Object.assign({}, messages.success_message_title)),
                        summary: _jsx(FormattedMessage, Object.assign({}, messages.success_message_summary)),
                    });
                    setEditProfilePic(false);
                },
                onError: (error) => {
                    setIsLoading(false);
                    showNotification({
                        variant: 'danger',
                        title: _jsx(FormattedMessage, Object.assign({}, messages.error_message_title)),
                        summary: (_jsxs(_Fragment, { children: [_jsx(FormattedMessage, Object.assign({}, messages.error_message_summary)), _jsx("span", { children: error.errorMessage })] })),
                    });
                },
            },
        });
    };
    const handleSubmit = () => {
        if (!(selectedFile && onUpdateProfilePhoto && data.id))
            return;
        setIsLoading(true);
        onUpdateProfilePhoto({
            body: {
                file: selectedFile,
                additionalParameter: {
                    file_type_id: 6,
                    owner_id: data.id,
                },
            },
            callback: {
                onSuccess: (res) => {
                    updateProfilePic(res);
                },
                onError: (error) => {
                    setIsLoading(false);
                    showNotification({
                        variant: 'danger',
                        title: _jsx(FormattedMessage, Object.assign({}, messages.error_message_title)),
                        summary: (_jsxs(_Fragment, { children: [_jsx(FormattedMessage, Object.assign({}, messages.error_message_summary)), _jsx("span", { children: error.errorMessage })] })),
                    });
                },
            },
        });
    };
    return (_jsx("div", { className: "p-2", children: _jsxs("div", { className: "flex flex-col items-center justify-center", children: [data.url || selectedFile ? (_jsxs("div", { className: "relative w-[256px] h-[256px] rounded-full overflow-hidden", children: [_jsx("img", { src: selectedFile
                                ? URL.createObjectURL(selectedFile)
                                : data.url, alt: "Selected", className: "w-full h-full object-cover object-center rounded-full", loading: "lazy" }), _jsxs("div", { className: classNames('absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center ', 'bg-black bg-opacity-50 text-white text-lg rounded-full opacity-0', 'transition-opacity duration-300 hover:opacity-100'), onClick: handleDivClick, onKeyDown: (e) => handleEnterKeyPress(e, handleDivClick), role: "button", tabIndex: -1, children: [_jsx(FontAwesomeIcon, { icon: faCamera, className: "text-5xl" }), _jsx("div", { className: "text-base mt-2", children: _jsx(FormattedMessage, Object.assign({}, messages.change_profile_photo)) })] })] })) : (_jsxs("div", { className: classNames('border w-64 h-64 rounded-full flex flex-col items-center justify-center bg-gray-200 ', 'cursor-pointer'), onClick: handleDivClick, onKeyDown: (e) => handleEnterKeyPress(e, handleDivClick), role: "button", tabIndex: -1, "data-testid": "uploadProfilePicture", children: [_jsx(FontAwesomeIcon, { icon: faPlus, className: "text-gray-500 font-thin text-5xl mb-4" }), _jsx("div", { className: "text-gray-500 text-base", children: _jsx(FormattedMessage, Object.assign({}, messages.add_profile_photo)) })] })), _jsx("input", { type: "file", ref: fileInputRef, onChange: (event) => handleImageUpload({
                        event,
                        callback: {
                            onSuccess: storeSelectedFile,
                            onError: fileUploadError,
                        },
                        supportedFileTypes,
                        ignoreConversion: true,
                    }), style: { display: 'none' }, accept: "image/*", "data-testid": "input-file" }), _jsxs("div", { className: "flex items-center mt-8", children: [_jsx("div", { className: "w-24", children: _jsx(Button, { className: "w-full", variant: "outline", onClick: () => setEditProfilePic(false), children: _jsx(FormattedMessage, Object.assign({}, messages.cancel)) }) }), _jsx("div", { className: "ml-4 w-28", children: _jsx(Button, { "data-testid": "save_profile_btn", className: "w-full", onClick: handleSubmit, disabled: !selectedFile, isLoading: isLoading, children: _jsx(FormattedMessage, Object.assign({}, messages.save)) }) })] })] }) }));
}
export default withUserActions(ProfilePic);
