import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import Modal from '@components/Base/Modal';
import messages from './messages';
const BackWarningModal = (props) => {
    const { isModalOpen, closeModal, goBack } = props;
    return (_jsxs(Modal, { size: "md", show: isModalOpen, onCancel: closeModal, children: [_jsx("div", { className: "text-xl font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.unsaved_changes)) }), _jsx("div", { className: "mt-3 mb-2 text-sm text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.unsaved_changes_warning)) }), _jsxs("div", { className: "flex items-center justify-end gap-3 w-full mt-6", children: [_jsx(Button, { variant: "destructive_outline", onClick: () => goBack(), children: _jsx(FormattedMessage, Object.assign({}, messages.discard)) }), _jsx(Button, { onClick: closeModal, children: _jsx(FormattedMessage, Object.assign({}, messages.keep_editing)) })] })] }));
};
export default BackWarningModal;
