import { all, call, put, takeLatest } from 'redux-saga/effects';
import fetchData from '@utils/fetchData';
import { GET_USER_LIST_QUERY } from './queries';
import { getUserListSuccessAction, getUserListErrorAction, getUserListAction, } from './slice';
export function* getUserListSaga() {
    try {
        const response = yield call(fetchData, {
            queryString: GET_USER_LIST_QUERY,
            queryKey: 'auth_user',
            forceRefresh: true,
        });
        if (response) {
            yield put(getUserListSuccessAction(response));
        }
    }
    catch (error) {
        yield put(getUserListErrorAction(error));
    }
}
export function* watchGetUserList() {
    yield takeLatest(getUserListAction.type, getUserListSaga);
}
export function* userListSaga() {
    yield all([watchGetUserList()]);
}
