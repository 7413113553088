import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const positionClassesMap = {
    'top-middle': {
        container: 'bottom-full mb-2',
        arrow: 'top-6 left-1/2 transform -translate-x-1/2 -mb-2',
    },
    'top-left': {
        container: 'bottom-full mb-2 left-0',
        arrow: 'top-6 left-5 transform -translate-x-1/2 -mb-1',
    },
    'top-right': {
        container: 'bottom-full mb-2 right-0',
        arrow: 'top-6 right-5 transform translate-x-1/2 -mb-1',
    },
    'bottom-middle': {
        container: 'top-full mt-2',
        arrow: 'bottom-6 left-1/2 transform -translate-x-1/2 -mt-1',
    },
    'bottom-left': {
        container: 'top-full mt-2 left-0',
        arrow: 'bottom-6 left-5 transform -translate-x-1/2 -mt-1',
    },
    'bottom-right': {
        container: 'top-full mt-2 right-0',
        arrow: 'bottom-6 right-5 transform translate-x-1/2 -mt-2',
    },
};
const Tooltip = ({ children, text, isVisible, position = 'top-middle', id, }) => {
    const positionClasses = positionClassesMap[position];
    return (_jsxs("div", { className: "relative flex flex-col items-center group", "data-testid": `${id}-tooltip`, children: [children, _jsxs("div", { className: `absolute flex flex-col items-center ${positionClasses.container} ${isVisible ? 'flex' : 'hidden'} group-hover:flex`, children: [_jsx("span", { className: "relative z-10 p-2.5 text-xs leading-none text-white whitespace-nowrap bg-black rounded shadow-lg", children: text }), _jsx("div", { className: `absolute w-3 h-3 rotate-45 bg-black ${positionClasses.arrow}` })] })] }));
};
export default Tooltip;
