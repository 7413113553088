import { isEqual } from 'lodash';
import { FEED_TYPE, VERIFICATION_TYPE, VERSION_LOG_KEY_VALUE_MAPTING, KeysToAddNewKeyValues, keyGroups, } from '@utils/data/enums';
const nonRelevantKeys = [
    'updated_by',
    'updated_at',
    'created_at',
    'created_by',
    '__typename',
    'flow_id',
    'version',
    'status',
];
export const transformConfigToPayload = (config) => {
    const payload = Object.assign({}, config);
    nonRelevantKeys.forEach((key) => {
        delete payload[key];
    });
    return payload;
};
const excludedKeys = [
    'updated_at',
    'updated_by',
    'id',
    '__typename',
    'flow_id',
    'created_by',
    'created_at',
    'version',
    'branding_logo_file_id',
    'user_by_created_by',
    'photo_id_verification_type',
    'status',
];
const mapFlagResponse = (key, value) => {
    const conditionalMappings = {
        face_capture_verification: value === VERIFICATION_TYPE.VERIFY
            ? VERSION_LOG_KEY_VALUE_MAPTING.ENABLE
            : VERSION_LOG_KEY_VALUE_MAPTING.DISABLE,
        photo_id_verification: value === VERIFICATION_TYPE.VERIFY
            ? VERSION_LOG_KEY_VALUE_MAPTING.ENABLE
            : VERSION_LOG_KEY_VALUE_MAPTING.DISABLE,
        primary_camera_feed: value === FEED_TYPE.STREAM_AND_RECORD
            ? VERSION_LOG_KEY_VALUE_MAPTING.ENABLE
            : VERSION_LOG_KEY_VALUE_MAPTING.DISABLE,
        screen_share_recording: value === FEED_TYPE.STREAM_AND_RECORD
            ? VERSION_LOG_KEY_VALUE_MAPTING.ENABLE
            : VERSION_LOG_KEY_VALUE_MAPTING.DISABLE,
        secondary_camera_recording: value === FEED_TYPE.STREAM_AND_RECORD
            ? VERSION_LOG_KEY_VALUE_MAPTING.ENABLE
            : VERSION_LOG_KEY_VALUE_MAPTING.DISABLE,
        screen_feed: value === FEED_TYPE.DISABLE
            ? VERSION_LOG_KEY_VALUE_MAPTING.DISABLE
            : VERSION_LOG_KEY_VALUE_MAPTING.ENABLE,
        face: value === FEED_TYPE.DISABLE
            ? VERSION_LOG_KEY_VALUE_MAPTING.DISABLE
            : VERSION_LOG_KEY_VALUE_MAPTING.ENABLE,
        photo_id: value === FEED_TYPE.DISABLE
            ? VERSION_LOG_KEY_VALUE_MAPTING.DISABLE
            : VERSION_LOG_KEY_VALUE_MAPTING.ENABLE,
        secondary_camera_feed: value === FEED_TYPE.DISABLE
            ? VERSION_LOG_KEY_VALUE_MAPTING.DISABLE
            : VERSION_LOG_KEY_VALUE_MAPTING.ENABLE,
    };
    if (key in conditionalMappings) {
        return conditionalMappings[key];
    }
    if (typeof value === 'boolean') {
        return value
            ? VERSION_LOG_KEY_VALUE_MAPTING.ENABLE
            : VERSION_LOG_KEY_VALUE_MAPTING.DISABLE;
    }
    return value;
};
const processValue = (key, value) => value === null || value === undefined
    ? VERSION_LOG_KEY_VALUE_MAPTING.NOT_AVAILABLE
    : mapFlagResponse(key, value);
const getKeyName = (key, value) => {
    var _a, _b;
    const mapping = {
        screen_feed: {
            STREAM_AND_RECORD: VERSION_LOG_KEY_VALUE_MAPTING.SCREEN_SHARE_RECORDING,
            STREAM: VERSION_LOG_KEY_VALUE_MAPTING.SCREEN_SHARE_RECORDING,
        },
        photo_id: {
            VERIFY: VERSION_LOG_KEY_VALUE_MAPTING.PHOTO_ID_VERIFICATION,
            CAPTURE: VERSION_LOG_KEY_VALUE_MAPTING.PHOTO_ID_VERIFICATION,
        },
        face: {
            VERIFY: VERSION_LOG_KEY_VALUE_MAPTING.FACE_CAPTURE_VERIFICATION,
            CAPTURE: VERSION_LOG_KEY_VALUE_MAPTING.FACE_CAPTURE_VERIFICATION,
        },
        secondary_camera_feed: {
            STREAM_AND_RECORD: VERSION_LOG_KEY_VALUE_MAPTING.SECONDARY_CAMERA_RECORDING,
            STREAM: VERSION_LOG_KEY_VALUE_MAPTING.SECONDARY_CAMERA_RECORDING,
        },
    };
    return (_b = (_a = mapping[key]) === null || _a === void 0 ? void 0 : _a[value]) !== null && _b !== void 0 ? _b : null;
};
const extractChanges = (config) => Object.entries(config).reduce((changes, [key, value]) => {
    if (!excludedKeys.includes(key)) {
        const typedKey = key;
        changes[typedKey] = processValue(typedKey, value);
        if (KeysToAddNewKeyValues.includes(key)) {
            const newKeyName = getKeyName(key, value);
            newKeyName
                ? (changes[newKeyName] = processValue(newKeyName, value))
                : null;
        }
    }
    return changes;
}, {});
const findDifferences = (previous, current) => Object.entries(current).reduce((changes, [key, value]) => {
    const typedKey = key;
    if (!excludedKeys.includes(key) && !isEqual(value, previous[typedKey])) {
        if (key === keyGroups.photoVerification.photoIdKey ||
            key === keyGroups.photoVerification.faceCaptureKey) {
            const isPrevVerificationDisable = previous[typedKey] === VERIFICATION_TYPE.DISABLE &&
                value !== VERIFICATION_TYPE.DISABLE;
            const isPrevVerificationTypesValues = previous[typedKey] !== VERIFICATION_TYPE.DISABLE &&
                value !== VERIFICATION_TYPE.VERIFY &&
                value !== VERIFICATION_TYPE.CAPTURE;
            if (isPrevVerificationDisable || isPrevVerificationTypesValues) {
                changes[typedKey] = processValue(typedKey, value);
            }
        }
        if (key === keyGroups.cameraFeeds.screenFeedKey ||
            key === keyGroups.cameraFeeds.secondaryCameraFeedKey) {
            const isPreviousFeedDisable = previous[typedKey] === FEED_TYPE.DISABLE &&
                value !== FEED_TYPE.DISABLE;
            const isPreviousFeedValues = previous[typedKey] !== FEED_TYPE.DISABLE &&
                value !== FEED_TYPE.STREAM &&
                value !== FEED_TYPE.STREAM_AND_RECORD;
            if (isPreviousFeedDisable || isPreviousFeedValues) {
                changes[typedKey] = processValue(typedKey, value);
            }
        }
        if (key !== keyGroups.photoVerification.photoIdKey &&
            key !== keyGroups.photoVerification.faceCaptureKey &&
            key !== keyGroups.cameraFeeds.screenFeedKey &&
            key !== keyGroups.cameraFeeds.secondaryCameraFeedKey) {
            changes[typedKey] = processValue(typedKey, value);
        }
        if (KeysToAddNewKeyValues.includes(typedKey)) {
            const newKeyName = getKeyName(typedKey, value);
            newKeyName
                ? (changes[newKeyName] = processValue(newKeyName, value))
                : null;
        }
    }
    return changes;
}, {});
const createLogEntry = (config, changes) => ({
    version: `${VERSION_LOG_KEY_VALUE_MAPTING.VERSION} ${config.version}`,
    user: config.user_by_created_by,
    updatedAt: config.updated_at,
    id: config.id,
    status: config.status,
    changeLogs: [changes],
});
export const transformToVersionLog = (flowConfigs) => flowConfigs.reduceRight((acc, current, index, array) => {
    const changes = index === 0
        ? extractChanges(current)
        : findDifferences(array[index - 1], current);
    acc.push(createLogEntry(current, changes));
    return acc;
}, []);
