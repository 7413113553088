import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faBriefcase, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { formatRolesArray } from '@utils/helpers';
import messages from './messages';
function RoleOrganizations(props) {
    const { data } = props;
    return (_jsxs("div", { className: "bg-white border rounded-lg p-4", children: [_jsx("div", { className: "text-gray-900 text-sm font-semibold mb-2", children: _jsx(FormattedMessage, Object.assign({}, messages.roles_organization)) }), _jsxs("div", { className: "flex items-center mb-2", children: [_jsx(FontAwesomeIcon, { icon: faUser, className: "text-blue-700 w-4 p-2 bg-blue-50 rounded-full" }), _jsx("div", { className: "text-sm ml-2 text-gray-900", children: formatRolesArray(data.roles) || '-' })] }), _jsxs("div", { className: "flex items-center", children: [_jsx(FontAwesomeIcon, { icon: faBriefcase, className: "text-blue-700 w-4 p-2 bg-blue-50 rounded-full" }), _jsx("div", { className: "ml-2 text-sm text-gray-900", children: data.tenantName || '-' })] })] }));
}
export default RoleOrganizations;
