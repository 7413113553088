import { defineMessages } from 'react-intl';
export const scope = 'app.components.projects.confirm_message';
export default defineMessages({
    project_ready: {
        id: `${scope}.project_ready`,
        defaultMessage: 'Your project is ready!',
    },
    project_ready_description: {
        id: `${scope}.project_ready_description`,
        defaultMessage: 'Get ready to create custom flows and track sessions effortlessly!',
    },
    creating_project: {
        id: `${scope}.creating_project`,
        defaultMessage: 'Creating your project...',
    },
    updating_project: {
        id: `${scope}.updating_project`,
        defaultMessage: 'Updating your project...',
    },
    creating_project_description: {
        id: `${scope}.creating_project_description`,
        defaultMessage: 'Get ready to create custom flows and track sessions effortlessly!',
    },
    go_to_project: {
        id: `${scope}.go_to_project`,
        defaultMessage: 'Go to Project',
    },
});
