import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { get } from 'lodash';
import AttendeeOnboardingPanel from '@components/Flows/ConfigPanels/AttendeeOnboardingPanel';
import CustomBrandingPanel from '@components/Flows/ConfigPanels/CustomBrandingPanel';
import OffboardingPanel from '@components/Flows/ConfigPanels/OffboardingPanel';
import ConfigCardList from '@components/Flows/FlowDetails/ConfigCardList';
import { getAttendeeOnboardingConfig } from '@components/Flows/FlowDetails/helper';
import { CONFIG_TYPE } from '@utils/data/enums';
import VersionLog from 'app/components/Flows/ConfigPanels/VersionLog';
const FlowDetails = (props) => {
    const { config, saveConfig, updatedConfig, updateConfig, updateFlowConfigStatus, versionLog, createFlowConfigStatus, } = props;
    const [openedConfigType, setOpenedConfigType] = useState(CONFIG_TYPE.VERSION);
    const setDefaultPanelType = () => setOpenedConfigType(CONFIG_TYPE.VERSION);
    const attendeeOnboardingConfig = useMemo(() => getAttendeeOnboardingConfig(config), [config]);
    const updatedAttendeeOnboardingConfig = useMemo(() => {
        return getAttendeeOnboardingConfig(updatedConfig);
    }, [updatedConfig]);
    const getPanelType = useCallback((type) => {
        switch (type) {
            case CONFIG_TYPE.CUSTOM_BRANDING:
                return (_jsx(CustomBrandingPanel, { config: {
                        logo: {
                            id: `${config && config.branding_logo_file_id}`,
                        },
                        branding_primary_color: config && config.branding_primary_color,
                    }, setDefaultPanelType: setDefaultPanelType, updateFeedbackChange: updateConfig, saveConfig: saveConfig, updateFlowConfigStatus: updateFlowConfigStatus, createFlowConfigStatus: createFlowConfigStatus }));
            case CONFIG_TYPE.OFFBOARDING:
                return (_jsx(OffboardingPanel, { config: {
                        feedback: !!(updatedConfig && updatedConfig.feedback),
                    }, isUpdated: get(updatedConfig, 'feedback') !== get(config, 'feedback'), setDefaultPanelType: setDefaultPanelType, updateFeedbackChange: updateConfig, saveConfig: saveConfig, updateFlowConfigStatus: updateFlowConfigStatus, createFlowConfigStatus: createFlowConfigStatus }));
            case CONFIG_TYPE.ATTENDEE_ONBOARDING:
                return (_jsx(AttendeeOnboardingPanel, { config: attendeeOnboardingConfig, updatedConfig: updatedAttendeeOnboardingConfig, setDefaultPanelType: setDefaultPanelType, updateFeedbackChange: updateConfig, saveConfig: saveConfig, updateFlowConfigStatus: updateFlowConfigStatus, createFlowConfigStatus: createFlowConfigStatus }));
            default:
                return _jsx(VersionLog, { versionLog: versionLog });
        }
    }, [
        openedConfigType,
        updatedConfig,
        updateFlowConfigStatus.isConfigUpdateLoading,
        createFlowConfigStatus,
        versionLog,
    ]);
    return (_jsxs("div", { className: "flex w-full bg-white", children: [_jsx("div", { className: "flex-1 h-[calc(100vh-81px)] overflow-auto", children: _jsx(ConfigCardList, { openConfig: setOpenedConfigType, config: config }) }), _jsx("div", { className: "min-w-96 flex flex-col max-w-[408px] w-full shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] h-[calc(100vh-81px)] overflow-auto", children: getPanelType(openedConfigType) })] }));
};
export default FlowDetails;
