import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import 'react-phone-number-input/style.css';
import './styles.css';
import { faCheck, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import EllipsisAnimation from '@images/EllipsisAnimation.json';
export function PhoneNumberUpdate(props) {
    const { value = '', toggleEditPhoneNumber, isLoading, setIsLoading, data, } = props;
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isError, setIsError] = useState(true);
    useEffect(() => {
        if (phoneNumber) {
            setIsError(() => !isValidPhoneNumber(phoneNumber));
        }
        if (!phoneNumber) {
            setPhoneNumber(value);
        }
    }, [phoneNumber]);
    const handlePhoneUpdate = () => {
        setIsLoading(true);
        if (!isError) {
            setIsLoading(false);
        }
        // @TODO: Implement API call phone update
    };
    return (_jsx("div", { children: _jsxs("div", { className: "flex items-center", children: [_jsx("div", { "data-testid": "phone_input", children: _jsx(PhoneInput, { disabled: isLoading, international: true, value: data.phoneNumber, onChange: (value) => setPhoneNumber(value), defaultCountry: "IN" }) }), isLoading ? (_jsx("div", { className: "w-4 ml-1 h-2", "data-chromatic": "ignore", "data-testid": "ellipsis_loading", children: _jsx(LottiePlayer, { autoplay: true, loop: true, src: EllipsisAnimation }) })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "ml-3 text-lime-700 w-4 cursor-pointer", children: _jsx(FontAwesomeIcon, { icon: faCheck, onClick: handlePhoneUpdate, "data-testid": "edit_confirm_btn" }) }), _jsx("div", { className: "ml-3 text-rose-600 w-4 cursor-pointer", children: _jsx(FontAwesomeIcon, { icon: faXmark, onClick: () => toggleEditPhoneNumber(false), "data-testid": "edit_cancel_btn" }) })] }))] }) }));
}
export default PhoneNumberUpdate;
