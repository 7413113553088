import { defineMessages } from 'react-intl';
export const scope = 'app.components.Error';
export default defineMessages({
    something_went_wrong: {
        id: `${scope}.something_went_wrong`,
        defaultMessage: 'Something went wrong',
    },
    sub_text: {
        id: `${scope}.sub_text`,
        defaultMessage: 'You can try refreshing the page or going back to the previous page and try again.',
    },
    support: {
        id: `${scope}.support`,
        defaultMessage: 'support',
    },
    support_team: {
        id: `${scope}.support_team`,
        defaultMessage: 'team for assistance.',
    },
    contact: {
        id: `${scope}.contact`,
        defaultMessage: 'If the problem persists, please contact our',
    },
});
