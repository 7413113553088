import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { faPhone, faPenToSquare, faEnvelope, } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import PhoneNumberUpdate from '@components/PhoneNumberUpdate';
import { classNames } from '@utils/classNames';
import messages from './messages';
function ContactInformation(props) {
    const { data } = props;
    const [editPhoneNumber, toggleEditPhoneNumber] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (!data.phoneNumber) {
            toggleEditPhoneNumber(true);
        }
    }, [data.phoneNumber]);
    return (_jsxs("div", { className: "bg-white border rounded-lg p-4", children: [_jsx("div", { className: "text-gray-900 text-sm font-semibold mb-2", children: _jsx(FormattedMessage, Object.assign({}, messages.contact_information)) }), _jsxs("div", { className: classNames('flex mb-2', editPhoneNumber ? 'items-start' : 'items-center'), children: [_jsx(FontAwesomeIcon, { icon: faPhone, className: "text-blue-700 w-4 p-2 bg-blue-50 rounded-full" }), editPhoneNumber ? (_jsx("div", { className: "ml-2", children: _jsx(PhoneNumberUpdate, { toggleEditPhoneNumber: toggleEditPhoneNumber, isLoading: isLoading, setIsLoading: setIsLoading, data: { phoneNumber: data.phoneNumber || '' } }) })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "text-sm ml-2 text-gray-900", children: data.phoneNumber || '-' }), _jsx(FontAwesomeIcon, { icon: faPenToSquare, className: "w-4 ml-4 cursor-pointer", onClick: () => toggleEditPhoneNumber(true), "data-testid": "edit_icon" })] }))] }), _jsxs("div", { className: "flex items-center", children: [_jsx(FontAwesomeIcon, { icon: faEnvelope, className: "text-blue-700 w-4 p-2 bg-blue-50 rounded-full" }), _jsx("div", { className: "ml-2 text-sm text-gray-900", children: data.email || '-' })] })] }));
}
export default ContactInformation;
