import { find } from 'lodash';
import { ROLE } from '@containers/app/types';
export const meetingParticipantRole = {
    PARTICIPANT: 'PARTICIPANT',
    GUEST: 'GUEST',
    ORGANIZER: 'ORGANIZER',
};
export const getCurrentRole = (roles) => {
    if (find(roles, (role) => role.toLowerCase() === ROLE.TENANT_ADMIN.toLowerCase())) {
        return ROLE.TENANT_ADMIN;
    }
    return getDisplayRole(roles);
};
export const getDisplayRole = (roles) => {
    if (roles && Array.isArray(roles) && roles.length) {
        if (roles.includes(ROLE.TENANT_ADMIN)) {
            return ROLE.TENANT_ADMIN;
        }
        if (roles.includes(ROLE.ADMIN)) {
            return ROLE.ADMIN;
        }
        return roles[0];
    }
    return '';
};
