import English from '@translations/en.json';
import Spanish from '@translations/es.json';
import French from '@translations/fr.json';
import Japanese from '@translations/ja.json';
export var AppLocale;
(function (AppLocale) {
    AppLocale["English"] = "en";
    AppLocale["French"] = "fr";
    AppLocale["Spanish"] = "es";
    AppLocale["Japanese"] = "ja";
})(AppLocale || (AppLocale = {}));
export const appMessages = [
    {
        messages: English,
        locale: AppLocale.English,
    },
    {
        messages: French,
        locale: AppLocale.French,
    },
    {
        messages: Spanish,
        locale: AppLocale.Spanish,
    },
    {
        messages: Japanese,
        locale: AppLocale.Japanese,
    },
];
export const commonScope = 'app.common';
