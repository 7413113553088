import { defineMessages } from 'react-intl';
export const scope = 'app.containers.flow_list';
export default defineMessages({
    flows: {
        id: `${scope}.flows`,
        defaultMessage: 'Flows',
    },
    flow_creation_failed: {
        id: `${scope}.flow_creation_failed`,
        defaultMessage: 'Flow Creation Failed',
    },
    flow_could_not_be_created: {
        id: `${scope}.flow_could_not_be_created`,
        defaultMessage: 'The flow could not be created.',
    },
    flow_created: {
        id: `${scope}.flow_created`,
        defaultMessage: 'Flow created successfully',
    },
    flow_created_description: {
        id: `${scope}.flow_created_description`,
        defaultMessage: 'The flow has been created successfully.',
    },
});
