import { defineMessages } from 'react-intl';
export const scope = 'app.components.account_settings.timezone_setting';
export default defineMessages({
    header: {
        id: `${scope}.header`,
        defaultMessage: 'Timezone Settings',
    },
    timezone: {
        id: `${scope}.timezone`,
        defaultMessage: 'Timezone',
    },
    select_timezone: {
        id: `${scope}.select_timezone`,
        defaultMessage: 'Select Timezone*',
    },
    submit: {
        id: `${scope}.submit`,
        defaultMessage: 'Save Changes',
    },
});
