import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import ToggleButton from '@components/Base/ToggleButton';
import messages from '@components/Flows/ConfigPanels/messages';
import configCardMessages from '@components/Flows/ConfigureCard/messages';
import { FEED_TYPE } from '@utils/data/enums';
const CameraRecordings = (props) => {
    const { config, updateConfig } = props;
    const handleUpdateConfig = (updatedConfig) => {
        updateConfig(Object.assign(Object.assign({}, config), updatedConfig));
    };
    return (_jsx(_Fragment, { children: _jsx("div", { className: "mx-4", children: _jsxs("div", { className: "flex flex-col gap-4 mt-1 pb-1", children: [_jsxs("div", { className: "px-4 py-3 bg-white rounded-md shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)]", children: [_jsxs("div", { className: "flex items-center gap-3", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.screen_share)) }), _jsx("span", { className: "text-xs text-gray-600 font-roboto", children: _jsx(FormattedMessage, Object.assign({}, messages.screen_share_description)) })] }), _jsx(ToggleButton, { "data-testid": "screen_feed_switch", checked: config.screen_feed !== FEED_TYPE.DISABLE, onClick: (checked) => {
                                            handleUpdateConfig({
                                                screen_feed: checked ? FEED_TYPE.STREAM : FEED_TYPE.DISABLE,
                                            });
                                        }, disabled: false })] }), config.screen_feed !== FEED_TYPE.DISABLE && (_jsxs("div", { className: "flex items-center gap-3 mt-2.5", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.screen_recording)) }), _jsx("span", { className: "text-xs text-gray-600 font-roboto", children: _jsx(FormattedMessage, Object.assign({}, messages.screen_recording_description)) })] }), _jsx(ToggleButton, { "data-testid": "screen_recording_feed_switch", checked: config.screen_feed === FEED_TYPE.STREAM_AND_RECORD, onClick: (checked) => {
                                            handleUpdateConfig({
                                                screen_feed: checked
                                                    ? FEED_TYPE.STREAM_AND_RECORD
                                                    : FEED_TYPE.STREAM,
                                            });
                                        }, disabled: false })] }))] }), _jsxs("div", { className: "flex items-center gap-3 px-4 bg-white rounded-md", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, configCardMessages.primary_camera_recording)) }), _jsx("span", { className: "text-xs text-gray-600", children: _jsx(FormattedMessage, Object.assign({}, messages.primary_camera_recording_description)) })] }), _jsx(ToggleButton, { "data-testid": "primary_camera_feed_switch", checked: config.primary_camera_feed === FEED_TYPE.STREAM_AND_RECORD, onClick: (checked) => {
                                    handleUpdateConfig({
                                        primary_camera_feed: checked
                                            ? FEED_TYPE.STREAM_AND_RECORD
                                            : FEED_TYPE.STREAM,
                                    });
                                }, disabled: false })] }), _jsxs("div", { className: "px-4 py-3 mb-1 bg-white rounded-md shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)]", children: [_jsxs("div", { className: "flex items-center gap-3", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.secondary_camera)) }), _jsx("span", { className: "text-xs text-gray-600", children: _jsx(FormattedMessage, Object.assign({}, messages.secondary_camera_description)) })] }), _jsx(ToggleButton, { "data-testid": "secondary_camera_feed_switch", checked: config.secondary_camera_feed !== FEED_TYPE.DISABLE, onClick: (checked) => {
                                            handleUpdateConfig({
                                                secondary_camera_feed: checked
                                                    ? FEED_TYPE.STREAM
                                                    : FEED_TYPE.DISABLE,
                                            });
                                        }, disabled: false })] }), config.secondary_camera_feed !== FEED_TYPE.DISABLE && (_jsxs("div", { className: "flex items-center gap-3 mt-2.5", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.secondary_camera_recording)) }), _jsx("span", { className: "text-xs text-gray-600 font-roboto", children: _jsx(FormattedMessage, Object.assign({}, messages.secondary_camera_recording_description)) })] }), _jsx(ToggleButton, { "data-testid": "secondary_camera_recording_feed_switch", checked: config.secondary_camera_feed === FEED_TYPE.STREAM_AND_RECORD, onClick: (checked) => {
                                            handleUpdateConfig({
                                                secondary_camera_feed: checked
                                                    ? FEED_TYPE.STREAM_AND_RECORD
                                                    : FEED_TYPE.STREAM,
                                            });
                                        }, disabled: false })] }))] })] }) }) }));
};
export default CameraRecordings;
