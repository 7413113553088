import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { FormattedMessage } from 'react-intl';
import { handleEnterKeyPress } from '@utils/helpers';
import messages from './messages';
const Card = ({ count, label, imageProps: { alt, url: imageUrl }, description, onNavigate, }) => {
    const onHandleClick = (e) => {
        e.stopPropagation();
        onNavigate();
    };
    const handleClick = (e) => {
        onHandleClick(e);
    };
    const handleKeyDown = (e) => {
        handleEnterKeyPress(e, () => onHandleClick(e));
    };
    return (_jsxs("div", { "data-testid": `card-${label}`, className: "flex flex-col justify-between flex-shrink-0 p-5 bg-white shadow-md h-44 rounded-xl", children: [_jsxs("div", { className: "flex items-center justify-between", children: [_jsxs("div", { children: [_jsx("div", { className: "pb-4 text-base font-medium text-gray-500", children: label }), _jsxs("div", { className: "flex", children: [_jsx("div", { className: "text-3xl font-semibold tracking-wide text-black", children: count }), _jsx("div", { className: "mt-2 ml-3 text-base font-medium text-gray-500", children: description })] })] }), _jsx("img", { src: imageUrl, alt: alt, className: "w-16 h-16" })] }), _jsxs("button", { type: "button", tabIndex: 0, onClick: handleClick, onKeyDown: handleKeyDown, className: "flex items-center mt-6 text-base font-medium text-primary", children: [_jsx(ArrowTopRightOnSquareIcon, { className: "w-5 h-5 mr-1" }), _jsx(FormattedMessage, Object.assign({}, messages.view_all))] })] }));
};
export default Card;
