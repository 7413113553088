import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import { Textbox } from '@components/Base/Textbox';
import messages from '@components/Flows/ConfigPanels/messages';
import PanelHeader from '@components/Flows/ConfigPanels/PanelHeader';
import configCardMessages from '@components/Flows/ConfigureCard/messages';
const CustomBrandingPanel = (props) => {
    const { config: { branding_primary_color }, setDefaultPanelType, updateFeedbackChange, updateFlowConfigStatus, createFlowConfigStatus, saveConfig, } = props;
    const handleOnPrimaryColorChange = (e) => updateFeedbackChange({ branding_primary_color: `#${e.target.value}` });
    return (_jsxs("div", { "data-testid": "custom_branding_panel", children: [_jsx(PanelHeader, { label: _jsx(FormattedMessage, Object.assign({}, configCardMessages.custom_branding_label)), description: _jsx(FormattedMessage, Object.assign({}, configCardMessages.custom_branding_description)), setDefaultPanelType: setDefaultPanelType }), _jsxs("div", { className: "flex flex-col gap-8 p-5", children: [_jsx("div", { children: _jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.logo)) }), _jsxs("span", { className: "text-sm text-gray-600", children: [_jsx(FormattedMessage, Object.assign({}, messages.logo_description)), _jsxs("ul", { className: "ml-5 leading-5 list-disc", children: [_jsx("li", { children: _jsx(FormattedMessage, Object.assign({}, messages.logo_description_l1)) }), _jsx("li", { children: _jsx(FormattedMessage, Object.assign({}, messages.logo_description_l2)) }), _jsx("li", { children: _jsx(FormattedMessage, Object.assign({}, messages.logo_description_l3)) })] })] }), _jsx(Button, { className: "mt-3 text-sm w-fit", disabled: true, children: _jsx(FormattedMessage, Object.assign({}, messages.upload_logo)) })] }) }), _jsx("div", { children: _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx("span", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.primary_color)) }), _jsxs("div", { className: "relative", children: [_jsx("span", { className: 'absolute left-2 top-1/2 -translate-y-1/2', children: "#" }), _jsx(Textbox, { name: "primary_color", "data-testid": "primary_color", value: branding_primary_color
                                                ? branding_primary_color.slice(1, branding_primary_color.length)
                                                : '', className: "pl-[21px]", onChange: handleOnPrimaryColorChange, placeholder: "Enter hex code of your color", tabIndex: 0 })] }), _jsx(Button, { isLoading: updateFlowConfigStatus.isConfigUpdateLoading ||
                                        createFlowConfigStatus.isLoading, "data-testid": "save_button", className: "w-fit mt-3 text-sm", onClick: saveConfig, tabIndex: 0, children: _jsx(FormattedMessage, Object.assign({}, messages.save_changes)) })] }) })] })] }));
};
export default CustomBrandingPanel;
