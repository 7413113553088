var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Auth from '@utils/auth';
import { processQuery } from './processQuery';
export const makeRequest = (_a) => __awaiter(void 0, [_a], void 0, function* ({ variables, queryString, key, forceRefresh = false,
// context = {},
 }) {
    const apolloClient = yield Auth.client();
    if (!apolloClient) {
        throw new Error('Apollo client is undefined.');
    }
    // const defaultContext = getDefaultContext(Auth.graphToken!);
    const { data } = yield apolloClient.query({
        query: queryString,
        variables,
        fetchPolicy: forceRefresh ? 'network-only' : 'cache-first',
        // context: {
        //   ...defaultContext,
        //   ...context,
        // },
    });
    return key ? data[key] : data;
});
const fetchData = (_b) => __awaiter(void 0, [_b], void 0, function* ({ queryString, queryKey, queryVariables = {}, forceRefresh,
// context,
 }) {
    if (!queryString || typeof queryString !== 'string' || !queryKey) {
        return [];
    }
    const graphQuery = processQuery(queryString);
    if ('errorMessage' in graphQuery &&
        (graphQuery === null || graphQuery === void 0 ? void 0 : graphQuery.errorMessage) === 'Invalid Query') {
        throw graphQuery;
    }
    const response = yield makeRequest({
        variables: Object.assign({}, queryVariables),
        key: queryKey,
        queryString: graphQuery,
        forceRefresh,
        // context,
    });
    return response;
});
export default fetchData;
