import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@components/Base/Button';
import Modal from '@components/Base/Modal';
import { Textbox } from '@components/Base/Textbox';
import flowDetailsMessages from '@components/Flows/ConfigPanels/messages';
import messages from '@components/Flows/EditFlowNameModal/messages';
const EditFlowNameModal = (props) => {
    const { name, isModalOpen, closeModal, updateFlowName, updateFlowStatus } = props;
    const [flowName, setFlowName] = useState('');
    const Intl = useIntl();
    useEffect(() => {
        if (name === flowName) {
            closeModal();
        }
        setFlowName(name);
    }, [name]);
    const handleCloseModal = () => {
        setFlowName(name);
        closeModal();
    };
    return (_jsxs(Modal, { size: "sm", show: isModalOpen, onCancel: handleCloseModal, children: [_jsx("div", { className: "text-xl font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.edit_name)) }), _jsx("div", { className: "mt-3 mb-2 text-sm text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.name_label)) }), _jsx(Textbox, { placeholder: Intl.formatMessage(messages.placeholder), value: flowName, onChange: (e) => setFlowName(e.target.value), "data-testid": "edit_flow_name_input" }), _jsx("div", { className: "mt-1 text-xs text-red-700", children: updateFlowStatus.error && updateFlowStatus.error.message }), _jsxs("div", { className: "flex items-center justify-end w-full gap-3 mt-6", children: [_jsx(Button, { variant: "outline", "data-testid": "create_flow_button", onClick: handleCloseModal, children: _jsx(FormattedMessage, Object.assign({}, messages.go_back)) }), _jsx(Button, { isLoading: updateFlowStatus.loadingKey === 'name', onClick: () => updateFlowName({ name: flowName }), disabled: flowName === name, children: _jsx(FormattedMessage, Object.assign({}, flowDetailsMessages.save_changes)) })] })] }));
};
export default EditFlowNameModal;
