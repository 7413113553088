import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import Modal from '@components/Base/Modal';
import AddFlowModalTabs from '@components/Flows/AddFlowModal/AddFlowModalTabs';
import messages from '@components/Flows/AddFlowModal/messages';
import { ADD_FLOW_MODAL_TAB } from '@utils/data/enums';
import { handleEnterKeyPress } from '@utils/helpers';
const AddFlowModal = ({ handleToggleAddFlowModal, openAddFlowModal, templates, currentProject, flow: { isLoading: isCreatingFlow, error: createFlowError }, handleResetFlowState, handleCreateFlow, }) => {
    const [flowName, setFlowName] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [selectedDuplicateFlow, setSelectedDuplicateFlow] = useState('');
    const [isFlowNameExceedsMaxChar, setIsFlowNameExceedsMaxChar] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const handleCloseModal = () => {
        resetFlowNameState();
        handleToggleAddFlowModal();
    };
    const resetFlowNameState = () => {
        setSelectedTemplate('');
        setSelectedDuplicateFlow('');
        setFlowName('');
        setIsFlowNameExceedsMaxChar(false);
        createFlowError && handleResetFlowState();
    };
    const handleTabChange = (tabIndex) => {
        setSelectedTab(tabIndex);
        resetFlowNameState();
    };
    const onClickCreateFlowBtn = () => {
        const createFlowPayload = {
            name: flowName,
            project_id: currentProject.id,
            is_template: selectedTab === ADD_FLOW_MODAL_TAB.TEMPLATE,
        };
        if (selectedTab !== ADD_FLOW_MODAL_TAB.NEW_FLOW) {
            createFlowPayload.reference_flow_id =
                selectedTab === ADD_FLOW_MODAL_TAB.DUPLICATE_FLOW
                    ? selectedDuplicateFlow.id
                    : selectedTemplate.id;
        }
        handleCreateFlow(createFlowPayload);
    };
    const handleKeyDown = (e) => {
        handleEnterKeyPress(e, () => handleToggleAddFlowModal());
    };
    const isDisabled = useMemo(() => {
        switch (selectedTab) {
            case ADD_FLOW_MODAL_TAB.NEW_FLOW:
                return !flowName;
            case ADD_FLOW_MODAL_TAB.DUPLICATE_FLOW:
                return !(selectedDuplicateFlow && flowName);
            default:
                return !(selectedTemplate && flowName);
        }
    }, [selectedTab, flowName, selectedDuplicateFlow, selectedTemplate]);
    const handleDuplicateChange = (e) => {
        const selectedFlow = Object.assign({}, e);
        selectedFlow.value = `${selectedFlow.value} (Copy)`;
        setSelectedDuplicateFlow(e);
        setFlowName(selectedFlow.value);
    };
    const isDisableCreateFlowBtn = isDisabled ||
        isCreatingFlow ||
        !!isFlowNameExceedsMaxChar ||
        !!createFlowError;
    const handleflowNameChange = (e) => {
        const { value } = e.target;
        setFlowName(value);
        if (value.length >= 45) {
            setIsFlowNameExceedsMaxChar(true);
        }
        else {
            setIsFlowNameExceedsMaxChar(false);
            createFlowError && handleResetFlowState();
        }
    };
    return (_jsxs(Modal, { size: "lg", show: openAddFlowModal, onCancel: handleCloseModal, children: [_jsx("p", { className: "text-xl font-semibold leading-6 text-gray-900", id: "modal-title", children: _jsx(FormattedMessage, Object.assign({}, messages.add_flow)) }), _jsx("div", { className: "relative h-[410px]", children: _jsx(AddFlowModalTabs, { handleflowNameChange: handleflowNameChange, flowName: flowName, selectedTemplate: selectedTemplate, setSelectedTemplate: setSelectedTemplate, selectedDuplicateFlow: selectedDuplicateFlow, handleTabChange: handleTabChange, handleDuplicateChange: handleDuplicateChange, flows: currentProject.flows, templates: templates, isFlowNameExceedsMaxChar: isFlowNameExceedsMaxChar, createFlowError: createFlowError }) }), _jsxs("div", { className: "flex flex-row-reverse", children: [_jsx(Button, { "data-testid": "create_flow_button", onClick: onClickCreateFlowBtn, isLoading: isCreatingFlow, disabled: isDisableCreateFlowBtn, children: _jsx(FormattedMessage, Object.assign({}, messages.create_flow)) }), _jsx("div", { className: "mr-3", children: _jsx(Button, { variant: "outline", onClick: handleCloseModal, onKeyDown: handleKeyDown, disabled: isCreatingFlow, children: _jsx(FormattedMessage, Object.assign({}, messages.cancel)) }) })] })] }));
};
export default AddFlowModal;
