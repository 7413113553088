import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import ProfileAvatar from '@components/Base/Avatar';
import Badge from '@components/Base/Badge';
import messages from '@components/Flows/ConfigPanels/messages';
import PanelHeader from '@components/Flows/ConfigPanels/PanelHeader';
import ChangeLogList from '@components/Flows/ConfigPanels/VersionLog/ChangeLogList';
import { STATUS } from '@utils/data/enums';
const VersionLog = ({ versionLog }) => {
    return (_jsxs(_Fragment, { children: [_jsx(PanelHeader, { label: _jsx(FormattedMessage, Object.assign({}, messages.version_history)), description: _jsx(FormattedMessage, Object.assign({}, messages.version_history_description)), isCloseIcon: false }), _jsx("div", { className: "px-6 py-[18px] flex flex-col gap-7", children: versionLog.map((detail) => (_jsxs("div", { children: [_jsxs("div", { className: "text-base font-medium", children: [detail.version, detail.status === STATUS.DRAFT && (_jsxs(_Fragment, { children: [_jsxs("span", { className: "ml-1", children: ["(", _jsx(FormattedMessage, Object.assign({}, messages.version_history_unpublished)), ")"] }), _jsx("span", { className: "ml-1", children: _jsx(Badge, { type: "secondary", children: _jsx(FormattedMessage, Object.assign({}, messages.version_history_current)) }) })] }))] }), _jsxs("div", { className: "text-sm text-gray-600 my-1", children: [detail.status === STATUS.PUBLISHED ? (_jsx(FormattedMessage, Object.assign({}, messages.published_on))) : (_jsx(FormattedMessage, Object.assign({}, messages.updated_on))), format(new Date(detail.updatedAt), "dd LLLL yyyy 'at' h:mm bbb")] }), _jsxs("div", { className: "flex", children: [_jsx(ProfileAvatar, { data: detail.user, size: "sm", bgColor: "bg-blue-100" }), _jsx("div", { className: "text-sm text-gray-600 ml-1", children: detail.user.name })] }), _jsx(ChangeLogList, { detail: detail })] }, detail.version))) })] }));
};
export default VersionLog;
