import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useContext } from 'react';
import { getDefaultLocale } from '@utils/helpers';
const defaultValue = {
    locale: getDefaultLocale(),
    switchLanguage: () => { },
};
const LanguageContext = createContext(defaultValue);
export const LanguageProvider = ({ children, }) => {
    const [locale, setLocale] = useState(getDefaultLocale());
    const switchLanguage = (language) => {
        setLocale(language);
    };
    return (_jsx(LanguageContext.Provider, { value: { locale, switchLanguage }, children: children }));
};
export const useLanguage = () => useContext(LanguageContext);
