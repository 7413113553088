var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { classNames } from '@utils/classNames';
const buttonVariants = cva('relative flex items-center justify-center px-3 py-[11px] h-[38px] text-sm rounded-md font-medium cursor-pointer outline-offset-2 disabled:cursor-not-allowed disabled:opacity-50 transition-all', {
    variants: {
        variant: {
            base: 'bg-primary text-white outline-primary enabled:hover:bg-primary-hover enabled:active:bg-primary-active',
            outline: 'bg-white text-primary border border-primary outline-primary outline-offset-[3px] enabled:hover:text-primary-hover enabled:hover:border-primary-hover enabled:active:text-primary-active enabled:active:border-primary-active',
            ghost: 'bg-white text-primary-ghost enabled:hover:bg-primary-ghost-hover outline-primary-ghost enabled:active:bg-primary-ghost-active',
            destructive: 'bg-red-700 text-white outline-red-700 enabled:hover:bg-red-800 enabled:active:bg-red-900',
            destructive_outline: 'bg-white text-red-700 border border-red-700 outline-red-700 outline-offset-[3px] enabled:hover:text-red-800 enabled:hover:border-red-800 enabled:active:text-red-900 enabled:active:border-red-900',
            destructive_ghost: 'bg-white text-red-700 outline-red-700 enabled:hover:bg-red-50 enabled:active:bg-red-100',
        },
    },
    defaultVariants: {
        variant: 'base',
    },
});
const Button = forwardRef((_a, ref) => {
    var { className, variant } = _a, props = __rest(_a, ["className", "variant"]);
    const { children, isLoading } = props, rest = __rest(props, ["children", "isLoading"]);
    return (_jsxs("button", Object.assign({ ref: ref, "aria-label": "Button", className: classNames(buttonVariants({ variant, className }), {
            'pointer-events-none': isLoading,
        }) }, rest, { children: [isLoading && (_jsx(FontAwesomeIcon, { "data-testid": "spinner", className: "absolute animate-spin", icon: faSpinnerThird })), _jsx("span", { className: classNames('flex items-center justify-center gap-2', isLoading && 'invisible'), children: children })] })));
});
Button.displayName = 'Button';
export { Button, buttonVariants };
