import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import Modal from '@components/Base/Modal';
import withUserActions from 'app/hoc/withUserActions';
import messages from './messages';
export const SignoutModal = (props) => {
    const { isModalOpen, closeModal, onSignout } = props;
    return (_jsxs(Modal, { size: "md", show: isModalOpen, onCancel: closeModal, children: [_jsx("div", { className: "text-xl font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.sign_out)) }), _jsx("div", { className: "mt-3 mb-2 text-sm text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.sign_out_warning)) }), _jsxs("div", { className: "flex items-center justify-end gap-3 w-full mt-6", children: [_jsx(Button, { variant: "outline", onClick: closeModal, children: _jsx(FormattedMessage, Object.assign({}, messages.go_back)) }), _jsx(Button, { onClick: onSignout, children: _jsx(FormattedMessage, Object.assign({}, messages.confirm)) })] })] }));
};
export default withUserActions(SignoutModal);
