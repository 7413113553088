var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { classNames } from '@utils/classNames';
const Textbox = forwardRef((_a, ref) => {
    var { className, label, helper, error, name, disabled, required } = _a, props = __rest(_a, ["className", "label", "helper", "error", "name", "disabled", "required"]);
    return (_jsxs("div", { className: classNames('text-gray-900', disabled && 'opacity-50 cursor-not-allowed'), children: [label && (_jsxs("label", { htmlFor: name, className: "text-sm", children: [label, required && _jsx("span", { className: "pl-0.5", children: "*" })] })), _jsx("input", Object.assign({ id: name, ref: ref, type: "text", disabled: disabled, className: classNames('my-1 block w-full rounded border-0 px-2 py-1.5 text-sm ring-1 ring-gray-200 placeholder:text-gray-400 focus:ring-primary sm:leading-6', error && 'ring-red-700 enabled:focus:ring-red-700', className) }, props)), helper && _jsx("div", { className: "text-xs", children: helper }), error && (_jsx("div", { "data-testid": "input_error", className: "text-xs text-red-700", children: error }))] }));
});
Textbox.displayName = 'Textbox';
export { Textbox };
