import { defineMessages } from 'react-intl';
export const scope = 'app.components.flows.flow_list';
export default defineMessages({
    add_flow: {
        id: `${scope}.add_flow`,
        defaultMessage: 'Add Flow',
    },
    search_flows: {
        id: `${scope}.search_flows`,
        defaultMessage: 'Search flows...',
    },
});
