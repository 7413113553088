var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { get, toNumber } from 'lodash';
import Apollo from '@utils/apollo';
import { getDisplayRole } from '@utils/data/roles';
import { parseJwt } from '@utils/helpers';
class Auth {
    constructor() {
        this.expires = null;
        if (!Auth.instance) {
            Auth.instance = this;
        }
    }
    get isLoggedIn() {
        return !!this.graphToken;
    }
    get hasuraClaims() {
        return get(this.user, 'https://hasura.io/jwt/claims', {});
    }
    get stored() {
        let st = {};
        try {
            st = JSON.parse(localStorage.getItem('tv.pc') || '{}');
        }
        catch (e) {
            // Sentry.captureException(e);
        }
        return st;
    }
    get graphToken() {
        return get(this.stored, 'access_token', null);
    }
    get user() {
        return get(this.stored, 'user', null);
    }
    get id() {
        const id = toNumber(get(this.stored, 'user.uid', null));
        return isNaN(id) || typeof id !== 'number' ? null : id;
    }
    get accountName() {
        return get(this.stored, 'user.organization', null);
    }
    get organization() {
        return this.hasuraClaims['X-Hasura-Tenant-Id'] || 0;
    }
    get roles() {
        return get(this.stored, 'roles', null);
    }
    get role() {
        return get(this.stored, 'role', null);
    }
    client() {
        return __awaiter(this, void 0, void 0, function* () {
            return Apollo.createClient({
                // @ts-expect-error defined in processToken
                graphToken: this.graphToken,
                role: this.role,
            });
        });
    }
    removeClient() {
        Apollo.removeClient();
    }
    createClient(_a) {
        return __awaiter(this, arguments, void 0, function* ({ errorCallback, graphToken, }) {
            yield this.processToken(graphToken);
            const client = yield Apollo.createClient({
                // @ts-expect-error defined in processToken
                graphToken: this.graphToken,
                errorCallback,
                role: get(this.roles, '0'),
            });
            return client;
        });
    }
    processToken(access_token) {
        return __awaiter(this, void 0, void 0, function* () {
            const user = parseJwt(access_token);
            const rest_token = user['rest-token'];
            const roles = user['https://hasura.io/jwt/claims']['X-Hasura-Allowed-Roles'];
            const role = getDisplayRole(roles);
            localStorage.setItem('tv.pc', JSON.stringify({ access_token, user, rest_token, role, roles }));
            this.expires = user.exp;
            return user;
        });
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            localStorage.removeItem('tv.pc');
            yield Apollo.clearCache();
            yield Apollo.removeClient();
            return Promise.resolve();
        });
    }
}
export const instance = new Auth();
export default instance;
