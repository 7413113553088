import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useRef, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { classNames } from '@utils/classNames';
const Accordion = ({ title, text, icon, children, isOpened, parentRef, contentWrapperHeight, }) => {
    const [isOpen, setIsOpen] = useState(isOpened);
    const childRef = useRef(null);
    const handleOnClick = () => {
        setIsOpen(!isOpen);
    };
    const getContentWrapperHeight = () => {
        if (parentRef === null || parentRef === void 0 ? void 0 : parentRef.current) {
            return parentRef.current.clientHeight;
        }
        else if (childRef.current) {
            return childRef.current.clientHeight;
        }
    };
    const contentHeight = useMemo(() => getContentWrapperHeight(), [contentWrapperHeight, isOpen]);
    return (_jsxs("div", { className: "shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)] bg-white rounded py-3", children: [_jsxs("div", { className: "relative isolate flex items-center justify-between px-4", children: [_jsxs("div", { className: "flex items-center", children: [icon && (_jsx("div", { className: "bg-gray-100 px-[5px] py-4 flex justify-center items-center rounded", children: icon })), _jsxs("div", { className: "pl-2", children: [_jsx("div", { className: "text-base font-semibold", children: title }), _jsx("div", { className: "text-xs text-gray-600 font-normal", children: text })] })] }), _jsx("button", { "aria-expanded": isOpen, "aria-label": "Toggle accordion button", "data-testid": "switch-button", onClick: handleOnClick, children: _jsx(ChevronDownIcon, { "data-testid": "chevron-down-icon", className: classNames('h-6 w-6 ml-1 text-gray-500 transition-transform duration-500', isOpen ? '-rotate-180 ' : '-rotate-0') }) })] }), _jsx("div", { "data-testid": "accordion-content", "aria-hidden": !isOpen, className: classNames('overflow-hidden duration-500 transition-all', isOpen && 'mt-3'), style: {
                    maxHeight: isOpen ? `${contentHeight}px` : 0,
                }, children: _jsx("div", { ref: parentRef !== null && parentRef !== void 0 ? parentRef : childRef, className: classNames('transition-all duration-500', isOpen ? 'opacity-100' : 'opacity-0'), children: children }) })] }));
};
export { Accordion };
