export const GET_USER_PROFILE_DETAILS = `
  query getCurrentUser {
    auth_user_me {
      id
      name
      username
      email
      old_id
      tenant {
        name
        id
        subdomain
      }
      phone_number
      external_id
      profile_pic_file_id
      # timezone
    }
      md_timezone(
      order_by: {value: asc}
    ) {
      description
      value
    }
  }
`;
export const FETCH_FILE_URL = `
query getFileUrl($id: Int!) {
  file_download_file(id: $id) {
    resource_url
    id
  }
}
`;
export const UPLOAD_FILE = `
mutation uploadFile($file_type_id: Int!, $original_name: String!, $owner_id: Int!, $extension:String!){
  file_upload_file( file_type_id : $file_type_id , original_name:$original_name ,
  owner_id:  $owner_id,extension: $extension ){
    id
    data
    expires_in
    key
  }
}
`;
export const UPDATE_USER = `
mutation updateUser($payload: auth_update_user_input!){
  auth_update_user(data: $payload){
    error_message
    success
  }
}
`;
