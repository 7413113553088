function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
const constructFullName = (firstName, lastName, middleName, isOnlyFirstName) => {
    if (firstName || lastName) {
        if (isOnlyFirstName) {
            return capitalizeFirstLetter(firstName);
        }
        return `${capitalizeFirstLetter(firstName)} ${middleName ? `${capitalizeFirstLetter(middleName)} ` : ''}${capitalizeFirstLetter(lastName)}`;
    }
    return '';
};
export default function getDisplayName(data, isOnlyFirstName = false, isOnlyName = false) {
    var _a;
    if (!data)
        return '';
    let fullName = '';
    const name = (data.name || '').trim();
    if (name) {
        fullName = name;
    }
    else {
        const firstName = (data.first_name || '').trim();
        const middleName = (data.middle_name || '').trim();
        const lastName = (data.last_name || '').trim();
        fullName = constructFullName(firstName, lastName, middleName, isOnlyFirstName);
    }
    return isOnlyName ? fullName : (_a = (fullName || data.email)) !== null && _a !== void 0 ? _a : '';
}
