import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import flowListMessage from '@components/Flows/AddFlowModal/messages';
import { Button } from 'app/components/Base/Button';
import messages from './messages';
import SettingIcon from '../../../images/setting-icon.svg';
const NoFlowsPage = ({ handleToggleModalOpen }) => {
    return (_jsx("div", { "data-testid": "no-flows-page", className: "flex justify-center w-[calc(100vw-5rem)] h-[calc(100vh-5rem)]", children: _jsxs("div", { className: "text-center text-gray-900 mt-52", children: [_jsx("div", { className: "flex justify-center mb-6", children: _jsx("img", { src: SettingIcon, alt: "setting-icon", className: "w-20 h-20" }) }), _jsx("div", { className: "text-lg font-semibold", children: _jsx(FormattedMessage, Object.assign({}, messages.no_flow_created)) }), _jsx("div", { className: "text-sm font-normal w-72", children: _jsx(FormattedMessage, Object.assign({}, messages.no_flow_created_description)) }), _jsx("div", { className: "flex justify-center mt-5", children: _jsx(Button, { onClick: handleToggleModalOpen, children: _jsx(FormattedMessage, Object.assign({}, flowListMessage.add_flow)) }) })] }) }));
};
export default NoFlowsPage;
