import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import User from '@components/Base/UserDetails';
import { classNames } from '@utils/classNames';
import ContactInformation from './ContactInformation';
import LastLogin from './LastLogin';
import ProfilePic from './ProfilePic';
import RoleOrganizations from './RoleOrganizations';
export function Profile(props) {
    const { user } = props;
    const [editProfilePic, setEditProfilePic] = useState(false);
    return (_jsx("div", { className: "p-6", children: editProfilePic ? (_jsx(ProfilePic, { setEditProfilePic: setEditProfilePic, data: user })) : (_jsxs("div", { children: [_jsxs("div", { className: "relative", children: [_jsx(User, { data: user, size: "md", additionalContent: user.external_id, avatarProps: { size: 'xl' } }), _jsx(FontAwesomeIcon, { "data-testid": "pencil_icon", icon: faPen, className: classNames('font-semibold absolute rounded-full bg-blue-700 text-white top-12 left-14 w-3 h-3 p-2 cursor-pointer'), onClick: () => setEditProfilePic(true) })] }), _jsx("div", { className: "mt-10", children: _jsx(ContactInformation, { data: { phoneNumber: user.phone_number, email: user.email } }) }), _jsx("div", { className: "mt-4", children: _jsx(RoleOrganizations, { data: {
                            roles: user.roles,
                            tenantName: user.tenant.name,
                        } }) }), _jsx("div", { className: "mt-4", children: _jsx(LastLogin, { data: user.metadata.lastSignInTime }) })] })) }));
}
export default Profile;
