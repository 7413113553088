import { useEffect, useState } from 'react';
import { getFileUrlAction } from '@containers/app/slice';
import { useAppDispatch } from '@utils/hooks';
const useFetchFileUrl = (profile_pic_id) => {
    const dispatch = useAppDispatch();
    const [url, setUrl] = useState('');
    useEffect(() => {
        if (profile_pic_id) {
            const payload = {
                queryVariables: {
                    id: profile_pic_id,
                },
                callback: {
                    onSuccess: (res) => {
                        setUrl(() => res.resource_url);
                    },
                },
            };
            dispatch(getFileUrlAction(payload));
        }
    }, [profile_pic_id]);
    return { url };
};
export default useFetchFileUrl;
