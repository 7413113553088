import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState, useEffect } from 'react';
import { faCheck, faCopy, faPencil } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/Base/Button';
import GroupDropdown from '@components/Base/GroupDropdown';
import Header from '@components/Base/Header';
import messages from '@components/Base/Header/messages';
import Tooltip from '@components/Base/Tooltip';
import BackWarningModal from '@components/Flows/FlowHeader/BackWarningModal';
import { STATUS } from '@utils/data/enums';
const FlowHeader = (props) => {
    const { options, selectedFlow, selectedProject, handleProjectChange, openUpdateNameModal, setAsDefault, updateFlowState, publishFlow, isPublishedLoading, isFlowChanged, currentProject, } = props;
    const [showWarning, setShowWarning] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const navigate = useNavigate();
    const handleBack = () => {
        closeModal();
        navigate(`/flows?project_uuid=${currentProject.token}`);
    };
    const handleWarningForClosing = () => {
        if (isFlowChanged) {
            setShowWarning(true);
        }
        else
            handleBack();
    };
    const closeModal = () => {
        setShowWarning(false);
    };
    const copyText = () => {
        if (selectedFlow.external_id) {
            navigator.clipboard.writeText(selectedFlow.external_id);
            setIsCopied(true);
        }
    };
    useEffect(() => {
        if (isCopied) {
            const timer = setTimeout(() => {
                setIsCopied(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [isCopied]);
    // For creating header detail params
    const details = useMemo(() => ({
        label: selectedFlow.name,
        labelTooltip: selectedFlow.name,
        labelIcon: (_jsx(FontAwesomeIcon, { icon: faPencil, className: "w-4 h-4 cursor-pointer text-primary" })),
        onLabelIconClick: openUpdateNameModal,
        subText: selectedFlow.external_id,
        subTextIcon: isCopied ? (_jsx(Tooltip, { text: _jsx(FormattedMessage, Object.assign({}, messages.copied)), isVisible: isCopied, id: currentProject.token, children: _jsx(FontAwesomeIcon, { icon: faCheck, className: "w-4 h-4 text-primary" }) })) : (_jsx(FontAwesomeIcon, { icon: faCopy, className: "w-4 h-4 cursor-pointer text-primary" })),
        subTextTooltip: selectedFlow.external_id,
        onSubTextIconClick: copyText,
        handleBack: handleWarningForClosing,
        isCopied: isCopied,
    }), [selectedFlow, isCopied]);
    return (_jsxs(_Fragment, { children: [_jsx(Header, { isBackButton: true, details: details, children: _jsxs("div", { className: "flex items-center gap-3 grow justify-end", children: [selectedFlow.is_default ? (_jsx("span", { className: "px-2.5 py-0.5 text-blue-700 bg-blue-50 text-xs font-medium", children: _jsx(FormattedMessage, Object.assign({}, messages.default)) })) : (_jsx(Button, { variant: "ghost", isLoading: updateFlowState.loadingKey === 'is_default', disabled: false, className: "text-nowrap", onClick: setAsDefault, children: _jsx(FormattedMessage, Object.assign({}, messages.set_default)) })), _jsx(Button, { variant: "base", isLoading: isPublishedLoading, className: "text-nowrap", onClick: publishFlow, disabled: selectedFlow.status === STATUS.PUBLISHED, children: selectedFlow.status === STATUS.PUBLISHED ? (_jsx(FormattedMessage, Object.assign({}, messages.published))) : (_jsx(FormattedMessage, Object.assign({}, messages.publish_flow))) }), _jsx("div", { className: "max-w-[308px] min-w-52 w-full ml-4", children: _jsx(GroupDropdown, { selectedValue: selectedProject, options: options, onChange: handleProjectChange }) })] }) }), _jsx(BackWarningModal, { isModalOpen: showWarning, closeModal: closeModal, goBack: handleBack })] }));
};
export default FlowHeader;
