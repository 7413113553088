import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import ConfigureCard from '@components/Flows/ConfigureCard';
import AttendeeOnboarding, { cardDetail as AttendeeOnboardingCardDetails, } from '@components/Flows/ConfigureCard/AttendeeOnboarding';
import CustomBranding, { cardDetail as cBCardDetails, } from '@components/Flows/ConfigureCard/CustomBranding';
import Offboarding, { cardDetail as offboardingCardDetails, } from '@components/Flows/ConfigureCard/Offboarding';
const ConfigCardList = (props) => {
    const { config, openConfig } = props;
    const configCardsData = useMemo(() => config
        ? [
            {
                cardDetail: cBCardDetails,
                component: CustomBranding,
                componentProps: {
                    isLogoUploaded: !!config.branding_logo_file_id,
                    branding_primary_color: config.branding_primary_color,
                },
            },
            {
                cardDetail: AttendeeOnboardingCardDetails,
                component: AttendeeOnboarding,
                componentProps: config,
            },
            {
                cardDetail: offboardingCardDetails,
                component: Offboarding,
                componentProps: {
                    isFeedbackEnabled: config.feedback,
                },
            },
        ]
        : [], [config]);
    return (_jsx("div", { className: "p-5 pl-8 pr-7 flex flex-col gap-7", children: configCardsData.map(({ cardDetail, component: Component, componentProps }) => (_jsx(ConfigureCard, { cardDetail: cardDetail, openConfig: openConfig, children: _jsx(Component, Object.assign({}, componentProps)) }, cardDetail.type))) }));
};
export default ConfigCardList;
