import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { setGlobalErrorAction } from '@containers/app/slice';
import { GET_FLOW_BY_ID_QUERY, UPDATE_FLOW_CONFIG_MUTATION, UPDATE_FLOW_MUTATION, CREATE_FLOW_CONFIG_MUTATION, } from '@containers/flowDetails/queries';
import { getFlowConfigByIDAction, getFlowConfigByIDErrorAction, getFlowConfigByIDSuccessAction, updateFlowAction, updateFlowConfigAction, updateFlowConfigErrorAction, updateFlowConfigSuccessAction, updateFlowErrorAction, updateFlowSuccessAction, setVersionLogChanges, createFlowConfigAction, createFlowConfigSuccessAction, createFlowConfigErrorAction, } from '@containers/flowDetails/slice';
import { setCurrentProject } from '@containers/projects/slice';
import fetchData from '@utils/fetchData';
import postData from '@utils/postData';
export function* getFlowConfigByIDSaga({ payload, }) {
    try {
        const data = yield call(fetchData, {
            queryString: GET_FLOW_BY_ID_QUERY,
            queryKey: 'pc_flow',
            queryVariables: payload,
            forceRefresh: true,
        });
        if (Array.isArray(data) && data.length === 1) {
            yield put(getFlowConfigByIDSuccessAction(data[0]));
            yield put(setVersionLogChanges(data[0].flow_configs));
            yield put(setCurrentProject(data[0].project));
        }
    }
    catch (error) {
        yield put(getFlowConfigByIDErrorAction(error));
        yield put(setGlobalErrorAction(error));
    }
}
export function* updateFlowSaga({ payload }) {
    try {
        const response = yield call(postData, {
            queryString: UPDATE_FLOW_MUTATION,
            payload: payload,
        });
        if (response) {
            yield put(updateFlowSuccessAction(response.data.pc_update_flow));
        }
    }
    catch (error) {
        yield put(updateFlowErrorAction(error));
        yield put(setGlobalErrorAction(error));
    }
}
export function* fetchFlowConfigByID(uuid) {
    try {
        const data = yield call(fetchData, {
            queryString: GET_FLOW_BY_ID_QUERY,
            queryKey: 'pc_flow',
            queryVariables: { uuid },
            forceRefresh: true,
        });
        return data;
    }
    catch (error) {
        yield put(setGlobalErrorAction(error));
        return null;
    }
}
export function* updateFlowConfigSaga(action) {
    try {
        const { payload, flowUuid } = action.payload;
        const response = yield call(postData, {
            queryString: UPDATE_FLOW_CONFIG_MUTATION,
            payload: payload,
        });
        if (response) {
            yield put(updateFlowConfigSuccessAction(response.data.pc_update_flow_config));
            const flowData = yield* fetchFlowConfigByID(flowUuid);
            if (Array.isArray(flowData) && flowData.length === 1) {
                yield put(getFlowConfigByIDSuccessAction(flowData[0]));
                yield put(setVersionLogChanges(flowData[0].flow_configs));
            }
        }
    }
    catch (error) {
        yield put(updateFlowConfigErrorAction(error));
        yield put(setGlobalErrorAction(error));
    }
}
export function* createFlowConfigSaga(action) {
    try {
        const { payload, flowUuid } = action.payload;
        const response = yield call(postData, {
            queryString: CREATE_FLOW_CONFIG_MUTATION,
            payload: payload,
        });
        if (response && response.data && response.data.pc_create_flow_config) {
            yield put(createFlowConfigSuccessAction());
            const flowData = yield* fetchFlowConfigByID(flowUuid);
            if (Array.isArray(flowData) && flowData.length === 1) {
                yield put(getFlowConfigByIDSuccessAction(flowData[0]));
                yield put(setVersionLogChanges(flowData[0].flow_configs));
            }
        }
    }
    catch (error) {
        yield put(createFlowConfigErrorAction(error));
        yield put(setGlobalErrorAction(error));
    }
}
export function* watchGetFlowConfigByID() {
    yield takeLatest(getFlowConfigByIDAction.type, getFlowConfigByIDSaga);
}
export function* watchUpdateFlow() {
    yield takeLatest(updateFlowAction.type, updateFlowSaga);
}
export function* watchUpdateFlowConfig() {
    yield takeLatest(updateFlowConfigAction.type, updateFlowConfigSaga);
}
export function* watchCreateFlowConfig() {
    yield takeLatest(createFlowConfigAction.type, createFlowConfigSaga);
}
export default function* flowDetailSaga() {
    yield all([
        fork(watchUpdateFlow),
        fork(watchGetFlowConfigByID),
        fork(watchUpdateFlowConfig),
        fork(watchCreateFlowConfig),
    ]);
}
