import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import Dropdown from '@components/Base/Dropdown';
import { Textbox } from '@components/Base/Textbox';
import { MAX_45_CHARS, DUPLICATE_FLOW_ERROR } from '@utils/data/enums';
import duplicateFlow from '../../../../images/svgs/duplicateFlow.svg';
import newFlow from '../../../../images/svgs/newFlow.svg';
import template from '../../../../images/svgs/template.svg';
import messages from '../messages';
const getErrorMessage = (props) => {
    if (props.createFlowError && props.createFlowError === DUPLICATE_FLOW_ERROR) {
        return _jsx(FormattedMessage, Object.assign({}, messages.duplicate_title));
    }
    if (props.isFlowNameExceedsMaxChar) {
        return _jsx(FormattedMessage, Object.assign({}, messages.max_45_chars));
    }
    return '';
};
export const flowTabs = [
    {
        id: 1,
        src: newFlow,
        name: _jsx(FormattedMessage, Object.assign({}, messages.new_flow)),
        content: (props) => (_jsx("div", { children: _jsx(Textbox, { "data-testid": "newFlowName", label: _jsx(FormattedMessage, Object.assign({}, messages.name_of_flow)), required: true, placeholder: props.Intl.formatMessage(messages.unique_title), value: props.flowName, onChange: (e) => props.handleflowNameChange(e), maxLength: MAX_45_CHARS, error: getErrorMessage(props) }) })),
    },
    {
        id: 2,
        src: template,
        name: _jsx(FormattedMessage, Object.assign({}, messages.use_a_template)),
        content: (props) => (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex-1 pb-6", children: [_jsxs("label", { className: "text-gray-900 text-sm font-normal inline-block mb-2", htmlFor: "templateSelect", children: [_jsx(FormattedMessage, Object.assign({}, messages.select_a_template)), _jsx("span", { className: "pl-0.5", children: "*" })] }), _jsx(Dropdown, { id: "templateSelect", value: props.selectedTemplate, isSearchable: false, placeholder: _jsx(FormattedMessage, Object.assign({}, messages.select_template)), onChange: (e) => {
                                props.setSelectedTemplate(e);
                            }, options: props.templateOptions })] }), _jsx(Textbox, { "data-testid": "templateName", label: _jsx(FormattedMessage, Object.assign({}, messages.name_of_flow)), required: true, placeholder: props.Intl.formatMessage(messages.unique_title), value: props.flowName, onChange: (e) => props.handleflowNameChange(e), maxLength: MAX_45_CHARS, error: getErrorMessage(props) })] })),
    },
    {
        id: 3,
        src: duplicateFlow,
        name: _jsx(FormattedMessage, Object.assign({}, messages.duplicate)),
        content: (props) => (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex-1 pb-6", children: [_jsxs("label", { className: "text-gray-900 text-sm font-normal inline-block mb-2", htmlFor: "duplicateFlow", children: [_jsx(FormattedMessage, Object.assign({}, messages.select_flow_to_duplicate)), _jsx("span", { className: "pl-0.5", children: "*" })] }), _jsx(Dropdown, { id: "duplicateFlow", value: props.selectedDuplicateFlow, isSearchable: false, placeholder: _jsx(FormattedMessage, Object.assign({}, messages.select_from_existing)), onChange: (e) => {
                                props.handleDuplicateChange(e);
                            }, options: props.flowList })] }), _jsx(Textbox, { "data-testid": "duplicateFlowName", label: _jsx(FormattedMessage, Object.assign({}, messages.name_of_flow)), required: true, placeholder: props.Intl.formatMessage(messages.unique_title), value: props.flowName, onChange: (e) => props.handleflowNameChange(e), maxLength: MAX_45_CHARS, error: getErrorMessage(props) })] })),
    },
];
