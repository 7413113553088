import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { CONFIG_TYPE } from '@utils/data/enums';
import messages from './messages';
import offboarding from '../../../images/svgs/offboarding.svg';
export const cardDetail = {
    label: _jsx(FormattedMessage, Object.assign({}, messages.offboarding_label)),
    subText: _jsx(FormattedMessage, Object.assign({}, messages.offboarding_description)),
    logo: offboarding,
    type: CONFIG_TYPE.OFFBOARDING,
};
const Offboarding = (props) => {
    const { isFeedbackEnabled } = props;
    return (_jsx("div", { children: _jsxs("div", { className: "text-sm text-gray-900", children: [_jsx(FormattedMessage, Object.assign({}, messages.feedback)), " :", ' ', isFeedbackEnabled ? (_jsx(FormattedMessage, Object.assign({}, messages.enabled))) : (_jsx(FormattedMessage, Object.assign({}, messages.disabled)))] }) }));
};
export default Offboarding;
