import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from 'react';
import { Transition, TransitionChild } from '@headlessui/react';
import { FormattedMessage } from 'react-intl';
import Modal from '@components/Base/Modal';
import { classNames } from '@utils/classNames';
import { handleEnterKeyPress } from '@utils/helpers';
import ComingSoon from './ComingSoon';
import { options } from './common';
import { AccountSettingsTab } from './constants';
import LanguageSelect from './LanguageSelect';
import messages from './messages';
import Profile from './Profile';
import TimeZones from './TimeZones';
const AccountSettings = (props) => {
    const { user, isModalOpen, closeModal, selectedLanguage, handleSetLocale, timeZone, handleSetTimezone, selectedTimezone, } = props;
    const [tab, setTab] = useState(AccountSettingsTab.PROFILE);
    const renderAccountSettingTab = (tabName) => {
        switch (tabName) {
            case AccountSettingsTab.PROFILE:
                return _jsx(Profile, { user: user });
            case AccountSettingsTab.LANGUAGE:
                return (_jsx(LanguageSelect, { selectedLanguage: selectedLanguage, handleSetLocale: handleSetLocale }));
            case AccountSettingsTab.PASSWORD:
            case AccountSettingsTab.TIMEZONE:
                return (_jsx(TimeZones, { user: user, timeZone: timeZone, handleSetTimezone: handleSetTimezone, selectedTimezone: selectedTimezone }));
            default:
                return _jsx(ComingSoon, {});
        }
    };
    return (_jsx(Transition, { show: isModalOpen, as: Fragment, children: _jsx("div", { className: "fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50", children: _jsx(TransitionChild, { as: Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0 scale-95", enterTo: "opacity-100 scale-100", leave: "ease-in duration-200", leaveFrom: "opacity-100 scale-100", leaveTo: "opacity-0 scale-95", children: _jsxs(Modal, { className: "pt-6 pb-0 pl-6 pr-0", size: "xl", show: isModalOpen, onCancel: closeModal, children: [_jsx("span", { className: "text-xl font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.header)) }), _jsx("div", { className: "bg-gray-200 h-[1px] mt-5" }), _jsxs("div", { className: "flex h-[619px] border-t border-gray-200", children: [_jsx("div", { className: "w-[278px] pr-5 pt-4", children: options.map((option) => (_jsxs("div", { "data-testid": `${option.value}_option`, role: "button", tabIndex: -1, className: classNames('flex gap-x-2 px-[18px] py-[6px] items-center mb-2 w-[235px] hover:bg-gray-100', option.value === tab ? 'bg-blue-50' : ''), onClick: () => setTab(option.value), onKeyDown: (e) => handleEnterKeyPress(e, () => setTab(option.value)), children: [_jsx("span", { className: classNames(option.value === tab
                                                    ? 'text-blue-700 '
                                                    : 'text-gray-900'), children: option.icon }), _jsx("span", { className: classNames('text-sm truncate max-w-[210px]', option.value === tab
                                                    ? 'text-blue-700 font-medium'
                                                    : 'text-gray-900 font-normal'), title: option.label, children: option.label })] }, option.value))) }), _jsx("div", { className: "w-full rounded bg-gray-50", children: renderAccountSettingTab(tab) })] })] }) }) }) }));
};
export default AccountSettings;
