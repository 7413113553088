var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { setPersistence, signInWithCustomToken, browserLocalPersistence, signOut, } from 'firebase/auth';
import { trim } from 'lodash';
import Auth from '@utils/auth';
import { auth } from '@utils/firebase';
export const getUser = () => {
    return auth.currentUser;
};
export function authenticateToken(_a) {
    return __awaiter(this, arguments, void 0, function* ({ access_token, errorCallback, }) {
        if (!trim(access_token))
            throw new Error('Invalid Access Token');
        yield setPersistence(auth, browserLocalPersistence);
        const UC = yield signInWithCustomToken(auth, access_token);
        if (UC.user) {
            const token = yield UC.user.getIdToken();
            return Auth.createClient({
                graphToken: token,
                errorCallback: errorCallback ? errorCallback : () => { },
            });
        }
        return false;
    });
}
export function logout() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            if (auth.currentUser) {
                yield auth.currentUser.getIdToken(true);
            }
            yield signOut(auth);
            yield Auth.logout();
            return Promise.resolve();
        }
        catch (err) {
            return Promise.resolve();
        }
    });
}
