import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import getDisplayName from '@utils/getDisplayName';
const sizes = {
    xl: 'h-20 w-20 text-2xl',
    lg: 'h-9 w-9 text-sm',
    md: 'h-6 w-6 text-xs',
    sm: 'h-5 w-5 text-[.625rem]',
};
const ProfileAvatar = ({ data = {}, size = 'md', isInitials = false, customInitial = '', bgColor = 'bg-sky-200', }) => {
    const fullName = useMemo(() => getDisplayName(data), [data]);
    const initialContent = useMemo(() => {
        if (customInitial) {
            return customInitial;
        }
        if (fullName && fullName.length) {
            const names = fullName.split(' ');
            return names
                .slice(0, 2)
                .map((d) => d.substring(0, 1))
                .join('');
        }
        return ((data && data.id) || '').toString().substring(0, 2);
    }, [customInitial, data, fullName]);
    const displayPicture = useMemo(() => (data.profile_pic_file_id ? data.url : ''), [data.profile_pic_file_id, data.url]);
    if (isInitials || !displayPicture || customInitial) {
        return (_jsx("div", { className: classNames(get(sizes, size, sizes.md)), children: _jsx("div", { "aria-label": "avatar", className: classNames(`${bgColor} inline-flex items-center justify-center rounded-full`, get(sizes, size, sizes.md)), children: _jsx("div", { className: classNames('leading-non text-gray-900 font-normal'), children: initialContent }) }) }));
    }
    return (_jsx("div", { className: classNames(get(sizes, size, sizes.md)), children: _jsx("img", { className: classNames('inline-block rounded-full object-cover', get(sizes, size, sizes.md)), src: displayPicture, alt: "avatar", loading: "lazy" }) }));
};
export default memo(ProfileAvatar);
