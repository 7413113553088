import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import Dropdown from '@components/Base/Dropdown';
import { LOCAL_STORAGE_KEYS } from '@utils/data/enums';
import { formatTimeZone, setItemInLocalStorage } from '@utils/helpers';
import messages from './messages';
const TimeZones = (props) => {
    const { timeZone, selectedTimezone, handleSetTimezone } = props;
    const formattedTimeZoneOptions = formatTimeZone(timeZone);
    const [newTimezone, setNewTimezone] = useState(selectedTimezone);
    const handleClick = () => {
        setItemInLocalStorage(LOCAL_STORAGE_KEYS.TIMEZONE, JSON.stringify(newTimezone));
        handleSetTimezone(newTimezone);
    };
    const disabled = useMemo(() => {
        return get(newTimezone, 'value') === get(selectedTimezone, 'value');
    }, [newTimezone, selectedTimezone]);
    return (_jsxs("div", { className: "w-full p-6", children: [_jsx("div", { className: "pb-6 text-base font-medium leading-5 text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.timezone)) }), _jsx("div", { className: "mb-4 text-sm font-normal leading-4 text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.select_timezone)) }), _jsx("div", { className: "w-[470px]", children: _jsx(Dropdown, { value: newTimezone, name: "timezone-select", options: formattedTimeZoneOptions, onChange: (value) => setNewTimezone(value), placeholder: "Change timezone", id: "timezones", isSearchable: true, isSearchIcon: true }) }), _jsx("div", { className: "flex justify-end gap-3 pr-2 mt-10", children: _jsx(Button, { disabled: disabled, onClick: handleClick, children: _jsx(FormattedMessage, Object.assign({}, messages.submit)) }) })] }));
};
export default TimeZones;
