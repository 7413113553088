import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Card from '@components/Base/Card';
import GroupDropdown from '@components/Base/GroupDropdown';
import Header from '@components/Base/Header';
import ProjectLoading from '@components/Base/Loader';
import { triggerToast } from '@components/Base/Notification';
import SomethingWentWrong from '@components/Error/SomethingWentWrong';
import ProjectLanding from '@components/Projects/ProjectLanding';
import SectionDetails from '@components/Projects/SectionDetails';
import messages from '@components/Projects/SectionDetails/messages';
import { resetTriggerToast } from '@containers/projects/slice';
import { HeaderContext } from '@contexts/HeaderContext';
import { TOAST_VARIANT, TOAST_STATE } from '@utils/data/enums';
import { getMixpanelProperties } from '@utils/helpers';
import { useAppSelector, useAppDispatch } from '@utils/hooks';
import { proviewUserProjectPageOpenedEvent, proviewUserNewProjectCreatedEvent, } from '@utils/mixpanelActions';
import { handleProjectFormOpen } from './helpers';
import FlowIcon from '../../images/flows-icon.png';
const Projects = () => {
    const navigate = useNavigate();
    const { data: projects, isLoading, currentProject, error, triggerToast: triggerProjectToast, } = useAppSelector((state) => state.projects);
    const { user, isMixpanelInitialized } = useAppSelector((state) => state.app);
    const { isComplete: isCreateComplete, isSuccess: isNewProjectCreated } = useAppSelector((state) => state.projects.projectForm);
    const dispatch = useAppDispatch();
    const { handleProjectChange, options } = useContext(HeaderContext);
    const handleNavigate = () => {
        navigate(`/flows?project_uuid=${currentProject.token}`);
    };
    useEffect(() => {
        if (triggerProjectToast) {
            handleTriggerToast(triggerProjectToast);
            dispatch(resetTriggerToast());
        }
    }, [triggerProjectToast]);
    useEffect(() => {
        if (isNewProjectCreated && isMixpanelInitialized) {
            const mixpanelProperties = getMixpanelProperties(user);
            proviewUserNewProjectCreatedEvent(mixpanelProperties);
        }
    }, [isNewProjectCreated, isMixpanelInitialized]);
    useEffect(() => {
        if (currentProject.token && isMixpanelInitialized) {
            const mixpanelProperties = getMixpanelProperties(user, currentProject.name);
            proviewUserProjectPageOpenedEvent(mixpanelProperties);
            navigate(`/projects/${currentProject.token}`);
        }
    }, [currentProject.token, isMixpanelInitialized]);
    const handleTriggerToast = (triggerFlowConfigToast) => {
        switch (triggerFlowConfigToast) {
            case TOAST_STATE.PROJECT_DETAILS_SAVED:
                showToast(TOAST_VARIANT.SUCCESS, messages.details_updated_description, messages.details_updated);
                break;
            case TOAST_STATE.PROJECT_DETAILS_SAVE_FAILED:
                showToast(TOAST_VARIANT.DANGER, messages.details_update_failed_description, messages.details_update_failed);
                break;
            default:
                showToast(TOAST_VARIANT.DANGER, messages.something_went_wrong);
        }
    };
    const showToast = (variant, summary, title) => {
        triggerToast({
            variant,
            message: {
                title: _jsx(FormattedMessage, Object.assign({}, title)),
                summary: _jsx(FormattedMessage, Object.assign({}, summary)),
            },
        });
    };
    if (isLoading) {
        return !projects.length && _jsx(ProjectLoading, {});
    }
    if (error) {
        return _jsx(SomethingWentWrong, {});
    }
    return projects.length > 0 || isCreateComplete ? (_jsxs(_Fragment, { children: [_jsx(Header, { details: {
                    label: _jsx(FormattedMessage, Object.assign({}, messages.project_overview)),
                    labelTooltip: 'Project overview',
                }, children: _jsx("div", { className: "w-[308px]", children: _jsx(GroupDropdown, { selectedValue: { label: currentProject.name }, options: options, onChange: handleProjectChange }) }) }), currentProject && (_jsx(SectionDetails, Object.assign({}, currentProject, { handleEditProjectModal: handleProjectFormOpen }))), _jsx("div", { className: "box-border grid grid-cols-1 gap-4 px-4 mt-8 md:grid-cols-2 lg:grid-cols-4", children: _jsx(Card, { count: get(currentProject, 'flows.length', 0), label: _jsx(FormattedMessage, Object.assign({}, messages.flows)), imageProps: {
                        alt: 'Flows card',
                        url: FlowIcon,
                    }, onNavigate: handleNavigate }) })] })) : (_jsx(ProjectLanding, { handleOpenModal: handleProjectFormOpen }));
};
export default Projects;
