import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { CONFIG_TYPE, FEED_TYPE, VERIFICATION_TYPE } from '@utils/data/enums';
import messages from './messages';
import attendeeOnboarding from '../../../images/svgs/attendeeOnboarding.svg';
export const cardDetail = {
    label: _jsx(FormattedMessage, Object.assign({}, messages.ab_label)),
    subText: _jsx(FormattedMessage, Object.assign({}, messages.ab_description)),
    logo: attendeeOnboarding,
    type: CONFIG_TYPE.ATTENDEE_ONBOARDING,
};
const getFlagResponse = (flag) => {
    if (flag)
        return _jsx(FormattedMessage, Object.assign({}, messages.enabled));
    else
        return _jsx(FormattedMessage, Object.assign({}, messages.disabled));
};
const AttendeeOnboarding = (props) => {
    const { browser_sb_desktop, sb_kiosk_mode, browser_firefox_desktop, browser_firefox_mobile, browser_chrome_desktop, browser_chrome_mobile, browser_edge_desktop, browser_edge_mobile, browser_safari_mobile, environment_test, screen_feed, primary_camera_feed, face, photo_id, } = props;
    return (_jsxs("div", { className: "flex flex-wrap pb-5", children: [_jsxs("div", { className: "mb-5 text-sm text-gray-900 basis-1/2", children: [_jsx("span", { className: "font-medium", children: _jsx(FormattedMessage, Object.assign({}, messages.browser_config)) }), _jsxs("ul", { className: "ml-5 leading-5 list-disc", children: [_jsxs("li", { children: ["Secure Browser (SB): ", getFlagResponse(browser_sb_desktop)] }), _jsxs("li", { children: ["Kiosk Mode: ", getFlagResponse(sb_kiosk_mode)] }), _jsxs("li", { children: ["Mozilla Firefox Mobile: ", getFlagResponse(browser_firefox_mobile)] }), _jsxs("li", { children: ["Mozilla Firefox Desktop: ", getFlagResponse(browser_firefox_desktop)] }), _jsxs("li", { children: ["Google Chrome Mobile: ", getFlagResponse(browser_chrome_mobile)] }), _jsxs("li", { children: ["Google Chrome Desktop: ", getFlagResponse(browser_chrome_desktop)] }), _jsxs("li", { children: ["Microsoft Edge Mobile: ", getFlagResponse(browser_edge_mobile)] }), _jsxs("li", { children: ["Microsoft Edge Desktop: ", getFlagResponse(browser_edge_desktop)] }), _jsxs("li", { children: ["Safari Mobile: ", getFlagResponse(browser_safari_mobile)] })] })] }), _jsxs("div", { className: "text-sm text-gray-900 basis-1/2", children: [_jsx("span", { className: "font-medium", children: _jsx(FormattedMessage, Object.assign({}, messages.camera_recordings)) }), _jsxs("ul", { className: "ml-5 leading-5 list-disc", children: [_jsxs("li", { children: [_jsx(FormattedMessage, Object.assign({}, messages.screen_sharing)), ":", ' ', getFlagResponse(!!screen_feed && screen_feed !== FEED_TYPE.DISABLE)] }), _jsxs("li", { children: [_jsx(FormattedMessage, Object.assign({}, messages.primary_camera_recording)), ":", ' ', getFlagResponse(!!primary_camera_feed &&
                                        primary_camera_feed === FEED_TYPE.STREAM_AND_RECORD)] }), _jsxs("li", { children: [_jsx(FormattedMessage, Object.assign({}, messages.screen_sharing_recording)), ":", ' ', getFlagResponse(!!screen_feed && screen_feed === FEED_TYPE.STREAM_AND_RECORD)] })] })] }), _jsxs("div", { className: "text-sm text-gray-900 basis-1/2", children: [_jsx("span", { className: "font-medium", children: _jsx(FormattedMessage, Object.assign({}, messages.identity_permission)) }), _jsxs("ul", { className: "ml-5 leading-5 list-disc", children: [_jsxs("li", { children: [_jsx(FormattedMessage, Object.assign({}, messages.photo_id_capture)), ":", ' ', getFlagResponse(!!photo_id && photo_id === VERIFICATION_TYPE.CAPTURE)] }), _jsxs("li", { children: [_jsx(FormattedMessage, Object.assign({}, messages.face_capture)), ":", ' ', getFlagResponse(!!face && face === VERIFICATION_TYPE.CAPTURE)] })] })] }), _jsxs("div", { className: "text-sm font-medium text-gray-900 basis-1/2", children: [_jsx(FormattedMessage, Object.assign({}, messages.environment_test)), ":", ' ', getFlagResponse(environment_test)] })] }));
};
export default AttendeeOnboarding;
