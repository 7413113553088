// import { getHasuraHeaders } from '@utils/helpers';
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const authUrl = process.env.REACT_APP_AUTH_URL || 'https://admin.talview.org';
const baseURL = process.env.REACT_APP_BASE_URL || 'https://proview-console.talview.org';
export const redirect = (path) => {
    window.location.href = path;
};
export const redirectToAdminLogout = (tenantId_1, username_1, location_1, ...args_1) => __awaiter(void 0, [tenantId_1, username_1, location_1, ...args_1], void 0, function* (tenantId, username, location, skip = false) {
    var _a, _b;
    const hostNameValue = window.location.host;
    const subdomain = hostNameValue.split(`.${baseURL}`)[0];
    const from = ((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.from) || '/';
    const search = window.location.search || ((_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.search) || '';
    const searchParams = new URLSearchParams(search);
    const redirectUrl = encodeURIComponent(`${window.location.origin}${from}${searchParams.toString()}`);
    const path = `${authUrl}/logout`;
    searchParams.set('redirect_url', redirectUrl);
    if (subdomain !== baseURL && !subdomain.includes('localhost')) {
        searchParams.set('subdomain', subdomain);
    }
    if (!skip) {
        if (tenantId) {
            searchParams.set('tid', tenantId.toString());
        }
        if (username) {
            searchParams.set('username', username);
        }
    }
    redirect(`${path}?${searchParams.toString()}`);
});
export const loginRedirect = ({ location }) => {
    var _a, _b;
    const hostNameValue = window.location.host;
    const subdomain = hostNameValue.split(`.${baseURL}`)[0];
    const from = ((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.from) || '/';
    const search = ((_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.search) || '';
    const redirectUrl = encodeURIComponent(`${window.location.origin}${from}${search}`);
    const existingSearchParams = new URLSearchParams(search);
    const tid = existingSearchParams.get('tid');
    const userId = existingSearchParams.get('userId');
    const path = `${authUrl}/login`;
    const searchParams = new URLSearchParams();
    searchParams.set('redirect_url', redirectUrl);
    if (tid) {
        searchParams.set('tid', tid);
    }
    if (userId) {
        searchParams.set('userId', userId);
    }
    if (subdomain !== baseURL && !subdomain.includes('localhost')) {
        searchParams.set('subdomain', subdomain);
    }
    redirect(`${path}?${searchParams.toString()}`);
};
