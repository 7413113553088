import { get } from 'lodash';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { setGlobalErrorAction } from '@containers/app/slice';
import fetchData from '@utils/fetchData';
import postData from '@utils/postData';
import { GET_FLOW_LIST_DATA, CREATE_FLOW_MUTATION, TEMPLATE_LIST, } from './queries';
import { getFlowListErrorAction, getFlowListSuccessAction, createFlowSuccessAction, createFlowErrorAction, getTemplateListSuccessAction, getTemplateListErrorAction, getFlowListAction, createFlowAction, getTemplateListAction, } from './slice';
export function* createFlowSaga(action) {
    try {
        const response = yield call(postData, {
            queryString: CREATE_FLOW_MUTATION,
            payload: action.payload,
        });
        if (response && 'data' in response) {
            yield put(createFlowSuccessAction(response.data.pc_create_flow));
        }
    }
    catch (error) {
        const errorMessage = error instanceof Error ? error.message : String(error);
        yield put(createFlowErrorAction(errorMessage));
        yield put(setGlobalErrorAction(error));
    }
}
export function* getFlowList(action) {
    const { limit, offset, searchExpression, projectId, conditions } = action.payload;
    try {
        const response = yield call(fetchData, {
            queryString: GET_FLOW_LIST_DATA,
            queryKey: 'pc_flow',
            queryVariables: {
                limit,
                offset,
                searchExpression,
                projectId,
                conditions,
            },
            forceRefresh: true,
        });
        const aggregateResponse = yield call(fetchData, {
            queryString: GET_FLOW_LIST_DATA,
            queryKey: 'pc_flow_aggregate',
            queryVariables: {
                limit,
                offset,
                searchExpression,
                projectId,
                conditions,
            },
            forceRefresh: true,
        });
        const count = get(aggregateResponse, 'aggregate.count', 0);
        if (response) {
            yield put(getFlowListSuccessAction({ data: response, totalCount: count }));
        }
    }
    catch (error) {
        yield put(getFlowListErrorAction(error));
        yield put(setGlobalErrorAction(error));
    }
}
export function* getTemplateList() {
    try {
        const response = yield call(fetchData, {
            queryString: TEMPLATE_LIST,
            queryKey: 'pc_flow',
            queryVariables: {},
            forceRefresh: true,
        });
        if (response) {
            yield put(getTemplateListSuccessAction(response));
        }
    }
    catch (error) {
        yield put(getTemplateListErrorAction(error));
        yield put(setGlobalErrorAction(error));
    }
}
export function* watchGetFlowList() {
    yield takeLatest(getFlowListAction.type, getFlowList);
}
export function* watchCreateFlow() {
    yield takeLatest(createFlowAction.type, createFlowSaga);
}
export function* watchGetTemplateList() {
    yield takeLatest(getTemplateListAction.type, getTemplateList);
}
export function* flowListSagas() {
    yield all([watchGetFlowList(), watchCreateFlow(), watchGetTemplateList()]);
}
