/*
 * Profile Messages
 *
 * This contains all the text for the Profile component.
 */
import { defineMessages } from 'react-intl';
export const scope = 'app.components.account_settings.profile';
export default defineMessages({
    header: {
        id: `${scope}.header`,
        defaultMessage: 'This is the Profile component!',
    },
    change_profile_photo: {
        id: `${scope}.change_profile_photo`,
        defaultMessage: 'Change Profile Photo',
    },
    add_profile_photo: {
        id: `${scope}.add_profile_photo`,
        defaultMessage: 'Add Profile Photo',
    },
    cancel: {
        id: `${scope}.cancel`,
        defaultMessage: 'Cancel',
    },
    save: {
        id: `${scope}.save`,
        defaultMessage: 'Save',
    },
    roles_organization: {
        id: `${scope}.roles_organization`,
        defaultMessage: 'Role & Organization',
    },
    contact_information: {
        id: `${scope}.contact_information`,
        defaultMessage: 'Contact Information',
    },
    last_login: {
        id: `${scope}.last_login`,
        defaultMessage: 'Last Login on ',
    },
    success_message_title: {
        id: `${scope}.success_message_title`,
        defaultMessage: 'Profile pic updated successfully',
    },
    success_message_summary: {
        id: `${scope}.success_message_summary`,
        defaultMessage: 'Your profile pic has been updated successfully!',
    },
    error_message_title: {
        id: `${scope}.error_message_title`,
        defaultMessage: 'Profile pic updating failed',
    },
    error_message_summary: {
        id: `${scope}.error_message_summary`,
        defaultMessage: 'Your profile pic upload has failed!',
    },
});
