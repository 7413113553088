import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import messages from './messages';
import NoResultsFound from '../../images/svgs/noResultsFound.svg';
const EmptyRecord = ({ colSpan }) => {
    const state = {
        imageUrl: NoResultsFound,
        title: messages.empty_record_title.defaultMessage,
        description: messages.empty_record_description.defaultMessage,
    };
    return (_jsx("td", { colSpan: colSpan, children: _jsx("div", { className: "flex justify-center w-full h-[calc(100vh-15rem)]", children: _jsx("div", { className: "sticky left-0", children: _jsxs("div", { className: "flex flex-col items-center justify-center mt-24 text-center text-gray-900", children: [_jsx("img", { src: state.imageUrl, alt: "empty-table", className: "mb-[1.5rem]" }), _jsx("div", { className: "text-lg font-semibold", children: state.title }), _jsx("div", { className: "w-64 text-sm font-normal", children: state.description })] }) }) }) }));
};
export default EmptyRecord;
