import { store } from '@utils/store';
import { createProjectAction, editProjectAction, projectFormCompleteAction, setCurrentProject, setProjectFormModal, } from './slice';
export const createNewProject = (payload) => {
    const filteredPayload = Object.fromEntries(Object.entries(payload).filter(([key, value]) => key && Boolean(value.length)));
    store.dispatch(createProjectAction(filteredPayload));
};
export const editProject = (payload) => {
    store.dispatch(editProjectAction(payload));
};
export const createProjectComplete = (newProject) => {
    store.dispatch(setCurrentProject(newProject));
    store.dispatch(projectFormCompleteAction());
};
export const handleProjectFormOpen = (projectId) => {
    store.dispatch(setProjectFormModal({ isOpen: true, projectId: projectId || '' }));
};
export const handleProjectFormClose = () => {
    store.dispatch(setProjectFormModal({ isOpen: false }));
};
