import { GrowthBook } from '@growthbook/growthbook-react';
const growthBook = new GrowthBook({
    apiHost: process.env.GROWTHBOOK_API_HOST,
    clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
    enableDevMode: process.env.NODE_ENV !== 'production',
    subscribeToChanges: true,
    //   trackingCallback(experiment, result) {
    //     Mixpanel.track(process.env.GROWTHBOOK_EXPERIMENT_EVENT, {
    //       'Experiment name': experiment.key,
    //       'Variant name': result.variationId,
    //       $source: 'growthbook',
    //     });
    //   },
});
export var GROWTHBOOK_FLAGS;
(function (GROWTHBOOK_FLAGS) {
    GROWTHBOOK_FLAGS["LOGO_URL"] = "logo_url";
    GROWTHBOOK_FLAGS["MIX_PANEL_ENABLED"] = "mix_panel_enabled";
})(GROWTHBOOK_FLAGS || (GROWTHBOOK_FLAGS = {}));
export default growthBook;
