import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faArrowDown, faArrowUp, faSort, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable, } from '@tanstack/react-table';
import classNames from 'classnames';
import EmptyRecord from '@components/TableHelpers/EmptyRecord';
import TableLoader from './loader/TableLoader';
const FlowListTable = ({ data, columns, setPagination, pagination, isLoading, }) => {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        state: {
            pagination,
        },
        manualPagination: true,
    });
    const { getAllLeafColumns } = table;
    const getSizeClass = (size) => {
        return `w-[${size}px]`;
    };
    return (_jsx("div", { className: "max-h-[calc(100vh-12rem)] mt-5 overflow-x-auto", children: _jsxs("table", { className: "min-w-full table-fixed", children: [_jsx("thead", { className: "sticky top-0 z-[2] bg-gray-100", children: table.getHeaderGroups().map((headerGroup) => (_jsx("tr", { children: headerGroup.headers.map((header) => (_jsx("th", { className: classNames('px-6 py-3 text-sm font-medium tracking-wider text-left text-primary-text', getSizeClass(header.column.columnDef.maxSize)), children: header.column.getCanSort() ? (_jsxs("div", { className: "flex items-center", children: [flexRender(header.column.columnDef.header, header.getContext()), _jsx("button", { type: "button", onClick: header.column.getToggleSortingHandler(), className: header.column.getIsSorted()
                                            ? 'ml-2'
                                            : 'ml-2 text-gray-400', children: header.column.getIsSorted() === 'desc' ? (_jsx(FontAwesomeIcon, { icon: faArrowDown })) : header.column.getIsSorted() === 'asc' ? (_jsx(FontAwesomeIcon, { icon: faArrowUp })) : (_jsx(FontAwesomeIcon, { icon: faSort })) })] })) : (flexRender(header.column.columnDef.header, header.getContext())) }, header.id))) }, headerGroup.id))) }), _jsx("tbody", { className: "overflow-x-auto", children: isLoading ? (_jsx(TableLoader, { columns: getAllLeafColumns() })) : data.length === 0 ? (_jsx("tr", { "data-testid": "empty_record", children: _jsx(EmptyRecord, { colSpan: getAllLeafColumns().length }) })) : (_jsx(_Fragment, { children: table.getRowModel().rows.map((row) => (_jsx("tr", { id: row.id, className: classNames('odd:bg-white even:bg-slate-50'), children: row.getVisibleCells().map((cell) => (_jsx("td", { className: classNames('p-4 whitespace-nowrap', row.original.is_default && 'border-b border-[#1D4ED8]'), children: flexRender(cell.column.columnDef.cell, cell.getContext()) }, cell.id))) }, row.id))) })) })] }) }));
};
export default FlowListTable;
