import { defineMessages } from 'react-intl';
export const scope = 'app.component.flow_header';
export default defineMessages({
    publish_flow: {
        id: `${scope}.publish_flow`,
        defaultMessage: 'Publish Flow',
    },
    published: {
        id: `${scope}.published`,
        defaultMessage: 'Published',
    },
    default: {
        id: `${scope}.default`,
        defaultMessage: 'Default',
    },
    set_default: {
        id: `${scope}.set_default`,
        defaultMessage: 'Set as Default Flow',
    },
    preview: {
        id: `${scope}.preview`,
        defaultMessage: 'Preview Flow',
    },
    copied: {
        id: `${scope}.copied`,
        defaultMessage: 'Copied',
    },
});
