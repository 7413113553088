import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import { classNames } from '@utils/classNames';
import { flowTabs } from './Tabs';
const AddFlowModalTabs = ({ handleflowNameChange, selectedTemplate, setSelectedTemplate, selectedDuplicateFlow, handleTabChange, handleDuplicateChange, flowName, flows, templates, isFlowNameExceedsMaxChar, createFlowError, }) => {
    const [activeTab, setActiveTab] = useState(flowTabs[0].name);
    const [sliderWidth, setSliderWidth] = useState(0);
    const [sliderLeft, setSliderLeft] = useState(0);
    const tabRefs = useRef([]);
    const Intl = useIntl();
    const flowList = flows.map((flow) => ({
        value: flow.name,
        label: flow.name,
        id: flow.id,
    }));
    const templateOptions = templates.map((template) => ({
        value: template.name,
        label: template.name,
        project_id: template.project_id,
        id: template.id,
    }));
    useEffect(() => {
        const activeIndex = flowTabs.findIndex((tab) => tab.name === activeTab);
        const activeTabElement = tabRefs.current[activeIndex];
        if (activeTabElement) {
            setSliderWidth(activeTabElement.offsetWidth);
            setSliderLeft(activeTabElement.offsetLeft);
        }
        handleTabChange(activeIndex);
    }, [activeTab]);
    return (_jsxs("div", { className: "absolute w-[640px] h-[350px] mx-auto mt-4 -left-6", children: [_jsxs("div", { className: "relative flex space-x-1 bg-blue-700/5 p-1", children: [flowTabs.map((tab, index) => (_jsx("button", { type: "button", ref: (el) => {
                            tabRefs.current[index] = el;
                        }, onClick: () => setActiveTab(tab.name), className: classNames('flex items-center justify-center w-full py-2.5 rounded-lg text-gray-500 font-medium focus:outline-none', activeTab === tab.name ? 'text-blue-700' : ''), children: _jsxs("div", { className: "flex flex-col items-center", children: [_jsx("img", { src: tab.src, alt: `tab icon ${index}`, className: classNames('mr-1 rounded', activeTab === tab.name
                                        ? 'border-2 border-blue-600'
                                        : 'border-2 border-transparent') }), _jsx("span", { className: classNames('pt-4 font-semibold text-base', activeTab === tab.name ? 'text-blue-700' : ''), children: tab.name })] }) }, tab.id))), _jsx(motion.div, { className: "absolute bottom-0 h-1 bg-blue-700 rounded", initial: { width: 0, left: 0 }, animate: { width: sliderWidth - 20, left: sliderLeft + 5 }, transition: { type: 'spring', stiffness: 300, damping: 30 } })] }), _jsx("div", { className: "p-6 box-border", children: flowTabs.map((tab) => activeTab === tab.name ? (_jsx("div", { children: tab.content({
                        handleflowNameChange,
                        flowName,
                        selectedDuplicateFlow,
                        selectedTemplate,
                        setSelectedTemplate,
                        handleTabChange,
                        handleDuplicateChange,
                        Intl,
                        flowList,
                        templateOptions,
                        isFlowNameExceedsMaxChar,
                        createFlowError,
                    }) }, tab.id)) : null) })] }));
};
export default AddFlowModalTabs;
