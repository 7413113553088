export const GET_FLOW_BY_ID_QUERY = `
  query getFlowConfigByIdQuery($uuid: uuid!) {
      pc_flow(where: {uuid: {_eq: $uuid}}) {
      created_at
      created_by
      description
      flow_configs {
        user_by_created_by {
          name
          email
          tenant_id
        }
        branding_logo_file_id
        branding_primary_color
        version
        feedback
        status
        browser_chrome_desktop
        browser_chrome_mobile
        browser_edge_desktop
        browser_edge_mobile
        browser_firefox_desktop
        browser_firefox_mobile
        browser_safari_mobile
        browser_sb_desktop
        created_at
        created_by
        environment_test
        face
        flow_id
        primary_camera_feed
        photo_id_verification_type
        photo_id
        sb_blacklisted_apps
        sb_content_protection
        sb_kiosk_mode
        screen_feed
        secondary_camera_feed
        terms_and_conditions
        updated_at
        updated_by
        id
      }
      project {
        id
        name
        region
        token
        description
        tags {
          id
          key
          value
        }
        flows {
          name
          id
          uuid
        }
      }
      name
      uuid
      updated_by
      updated_at
      project_id
      is_default
      id
    }
  }`;
export const UPDATE_FLOW_MUTATION = `
  mutation updateFlow($payload: pc_update_flow_input!) {
    pc_update_flow(
      data: $payload
    ) {
      description
      is_active
      is_default
      is_template
      name
      uuid
      project_id
    }
  }
`;
export const UPDATE_FLOW_CONFIG_MUTATION = `
  mutation updateFlow($payload: pc_update_flow_config_input!) {
    pc_update_flow_config(data: $payload) {
      branding_logo_file_id
      branding_primary_color
      browser_chrome_desktop
      browser_chrome_mobile
      browser_edge_desktop
      browser_edge_mobile
      browser_firefox_desktop
      browser_firefox_mobile
      browser_sb_desktop
      browser_safari_mobile
      environment_test
      face
      feedback
      flow_id
      photo_id
      photo_id_verification_type
      primary_camera_feed
      sb_blacklisted_apps
      sb_content_protection
      sb_kiosk_mode
      screen_feed
      secondary_camera_feed
      status
      terms_and_conditions
    }
  }
`;
export const CREATE_FLOW_CONFIG_MUTATION = `
mutation createFlowConfig($payload: pc_create_flow_config_input!) {
  pc_create_flow_config(
    data: $payload
  ) {
    branding_logo_file_id
    branding_primary_color
    id
    browser_chrome_desktop
    browser_chrome_mobile
    browser_edge_desktop
    browser_edge_mobile
    browser_firefox_desktop
    browser_firefox_mobile
    browser_safari_mobile
    browser_sb_desktop
    created_at
    created_by
    environment_test
    face
    feedback
    flow_id
    photo_id
    photo_id_verification_type
    primary_camera_feed
    sb_blacklisted_apps
    sb_content_protection
    sb_kiosk_mode
    screen_feed
    secondary_camera_feed
    status
    terms_and_conditions
    updated_at
    updated_by
  }
}`;
