import { createListenerMiddleware, } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { get } from 'lodash';
import { setGlobalErrorAction } from './slice';
export const globalErrorListener = createListenerMiddleware();
const startListeningGlobalError = globalErrorListener.startListening;
startListeningGlobalError({
    actionCreator: setGlobalErrorAction,
    effect: ({ payload: error }) => {
        if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
            window.location.pathname = '/logout';
            return;
        }
        if (get(error, 'graphQLErrors.0.extensions.code') === 'start-failed') {
            return;
        }
        Sentry.captureException(error);
    },
});
export default globalErrorListener.middleware;
