import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { Popover, Transition, PopoverButton, PopoverPanel, } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import Avatar from '@components/Base/Avatar';
import { classNames } from '@utils/classNames';
import { formatRoles } from '@utils/helpers';
import SideNavProfileContent from './SideNavProfileContent';
const SideNavProfile = (props) => {
    const { user, isExpanded, selectedLanguage, handleSetLocale, handleSetTimezone, selectedTimezone, timeZone, } = props;
    const { name, currentRole } = user;
    return (_jsxs(Popover, { className: "relative", children: [_jsx(PopoverButton, { className: "w-full outline-none", children: _jsxs("div", { className: classNames('flex items-center cursor-pointer overflow-hidden transition-all duration-500 text-left', isExpanded ? 'w-full pl-3.5 pr-2' : 'px-3.5'), children: [_jsx(Avatar, { data: user }), _jsxs("div", { className: classNames('mr-auto ml-3 transition-all duration-1000', isExpanded ? 'opacity-100' : 'opacity-0'), children: [_jsx("div", { className: "text-sm text-gray-900 text-nowrap", children: name }), currentRole && (_jsx("div", { className: "text-xs text-gray-600 text-nowrap", children: formatRoles(currentRole) }))] }), _jsx(ChevronRightIcon, { height: 20, width: 20, className: "ml-auto text-gray-400" })] }) }), _jsx(Transition, { as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-100", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95", children: _jsx(PopoverPanel, { className: classNames('absolute bg-white bottom-0 z-10', isExpanded ? 'left-48' : 'left-20'), children: _jsx(SideNavProfileContent, { timeZone: timeZone, handleSetTimezone: handleSetTimezone, selectedTimezone: selectedTimezone, user: user, selectedLanguage: selectedLanguage, handleSetLocale: handleSetLocale }) }) })] }));
};
export default SideNavProfile;
