import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleEnterKeyPress } from '@utils/helpers';
const Header = (props) => {
    const { isBackButton, details, children } = props;
    const { label, labelTooltip = '', labelIcon, onLabelIconClick, subText = '', subTextTooltip = '', subTextIcon, onSubTextIconClick, handleBack, } = details;
    const handleLabelIconKeyPress = (e) => onLabelIconClick && handleEnterKeyPress(e, onLabelIconClick);
    const handleSubtextIconKeyPress = (e) => onSubTextIconClick && handleEnterKeyPress(e, onSubTextIconClick);
    return (_jsxs("header", { className: "flex items-center justify-between h-20 px-8 pt-4 pb-3 border-b border-gray-200", children: [_jsxs("div", { className: "flex flex-col items-start flex-1 w-full max-w-96 ", children: [_jsxs("div", { className: "flex items-center gap-3", children: [isBackButton && (_jsx(FontAwesomeIcon, { "data-testid": "back_btn", role: "button", icon: faArrowLeft, className: "w-6 h-6 cursor-pointer", onClick: handleBack, onKeyDown: (e) => handleBack && handleEnterKeyPress(e, handleBack), tabIndex: 0 })), label && (_jsxs("div", { className: "flex", children: [_jsx("span", { className: "mr-2 text-xl font-semibold text-gray-900 truncate max-w-80", title: labelTooltip, children: label }), _jsx("button", { type: "button", "data-testid": "label_icon", onClick: onLabelIconClick, tabIndex: 0, onKeyDown: handleLabelIconKeyPress, children: labelIcon })] }))] }), subText && (_jsxs("div", { className: "flex ml-9", children: [_jsx("span", { className: "mr-2 text-sm font-normal text-gray-700 truncate max-w-80", title: subTextTooltip, children: subText }), _jsx("button", { type: "button", "data-testid": "subText_icon", onClick: onSubTextIconClick, tabIndex: 0, onKeyDown: handleSubtextIconKeyPress, children: subTextIcon })] }))] }), children] }));
};
export default Header;
