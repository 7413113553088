import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Tabs from '@components/Base/Tabs';
import messages from './messages';
const SessionsList = ({ sessionData }) => {
    const [selectedTab, setSelectedTab] = useState('sessions');
    const handleTabSelect = (value) => {
        setSelectedTab(value);
    };
    return (_jsx("div", { className: "flex w-full px-8 py-5", children: sessionData.length > 0 ? (_jsx(Tabs, { tabData: [
                {
                    label: _jsx(FormattedMessage, Object.assign({}, messages.all_sessions)),
                    value: 'sessions',
                },
                {
                    label: _jsx(FormattedMessage, Object.assign({}, messages.requests)),
                    value: 'requests',
                },
            ], selectedValue: selectedTab, onSelect: handleTabSelect })) : (_jsx(_Fragment, {})) }));
};
export default SessionsList;
