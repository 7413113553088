import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { CONFIG_TYPE } from '@utils/data/enums';
import messages from './messages';
import customBranding from '../../../images/svgs/customBranding.svg';
export const cardDetail = {
    label: _jsx(FormattedMessage, Object.assign({}, messages.custom_branding_label)),
    subText: _jsx(FormattedMessage, Object.assign({}, messages.custom_branding_description)),
    logo: customBranding,
    type: CONFIG_TYPE.CUSTOM_BRANDING,
};
const CustomBranding = (props) => {
    const { isLogoUploaded, branding_primary_color } = props;
    return (_jsxs("div", { className: "flex flex-col gap-2", children: [_jsxs("div", { className: "text-sm text-gray-900", children: [_jsx(FormattedMessage, Object.assign({}, messages.logo)), " :", ' ', isLogoUploaded ? (_jsx(FormattedMessage, Object.assign({}, messages.uploaded))) : (_jsx(FormattedMessage, Object.assign({}, messages.not_uploaded)))] }), _jsxs("div", { className: "text-sm text-gray-900 flex items-center", children: [_jsx(FormattedMessage, Object.assign({}, messages.color)), " :", ' ', branding_primary_color ? (_jsxs(_Fragment, { children: [_jsx("div", { style: { backgroundColor: branding_primary_color }, className: "w-4 h-4 border border-gray-200 rounded-sm mx-1" }), branding_primary_color] })) : (_jsx(FormattedMessage, Object.assign({}, messages.not_uploaded)))] })] }));
};
export default CustomBranding;
