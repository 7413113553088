import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import CheckAnimation from '@images/CheckAnimation.json';
import FolderAnimation from '@images/FolderAnimation.json';
import messages from './messages';
const ConfirmMessage = ({ isSuccess, onSuccess, isEditProject }) => {
    return (_jsx("div", { className: "fixed inset-0 z-10 flex items-center justify-center bg-gray-900 bg-opacity-50", "aria-labelledby": "modal-title", "aria-modal": "true", children: _jsx("div", { className: "fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  w-full max-w-[525px] max-h-[621px] h-full overflow-hidden rounded-lg bg-white p-6 m-6 text-left shadow-xl transition-all z-10", children: _jsxs("div", { className: "text-center", children: [_jsx(LottiePlayer, { className: "relative max-w-[150px] w-full pt-24", autoplay: true, loop: true, src: isSuccess ? CheckAnimation : FolderAnimation }), _jsx("p", { className: "text-lg font-medium", children: isSuccess ? (_jsx(FormattedMessage, Object.assign({}, messages.project_ready))) : (_jsx(FormattedMessage, Object.assign({}, (isEditProject
                            ? messages.updating_project
                            : messages.creating_project)))) }), _jsx("p", { className: "max-w-56 mx-auto text-gray-500 text-sm mt-1.5", children: _jsx(FormattedMessage, Object.assign({}, messages.project_ready_description)) }), isSuccess && (_jsxs(Button, { onClick: onSuccess, className: "mx-auto mt-3", children: [_jsx(FormattedMessage, Object.assign({}, messages.go_to_project)), _jsx(FontAwesomeIcon, { icon: faArrowRight })] }))] }) }) }));
};
export default ConfirmMessage;
