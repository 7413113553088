import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { get } from 'lodash';
import { handleEnterKeyPress } from '@utils/helpers';
import { dataMap } from './notificationData';
const Content = ({ title, summary, onClose, actions, variant, }) => {
    const currentVariant = variant || 'default';
    const icon = get(dataMap, `${currentVariant}.icon`);
    const altText = get(dataMap, `${currentVariant}.altText`);
    const borderColor = get(dataMap, `${currentVariant}.borderColor`);
    const color = get(dataMap, `${currentVariant}.color`);
    const handleActionKeyPress = (action) => {
        return (e) => handleEnterKeyPress(e, () => action.onClick());
    };
    return (_jsxs("div", { className: classNames('flex items-start border-l-8 bg-white py-4 px-2 rounded shadow-toast w-[420px]', borderColor), children: [_jsx("div", { className: "flex items-start justify-center mt-1 w-11", "data-chromatic": "ignore", children: _jsx("img", { src: icon, alt: altText }) }), _jsxs("div", { className: "flex flex-col items-center justify-center flex-1", children: [title ? (_jsx("div", { className: "flex justify-between w-full", children: _jsxs("div", { children: [_jsx("span", { className: "self-stretch text-sm font-semibold leading-5 text-gray-900", children: title }), summary && (_jsx("p", { className: "pt-1 text-sm font-normal text-gray-900", children: summary }))] }) })) : (_jsx("div", { className: "flex items-center justify-between w-full h-full", children: summary && (_jsx("p", { className: "pt-1 text-sm font-normal text-gray-900", children: summary })) })), _jsx("div", { className: "flex items-center justify-start w-full space-x-4", children: actions &&
                            actions.map((action) => (_jsx("span", { "data-testid": `toast-action-${action.label}`, role: "button", tabIndex: 0, className: classNames('cursor-pointer text-sm font-semibold mt-2', color), onClick: action.onClick, onKeyDown: handleActionKeyPress(action), children: action.label }, action.label))) })] }), _jsx("div", { className: "pr-4 pl-4 pt-[2px] cursor-pointer", children: _jsx(XMarkIcon, { role: "presentation", className: "w-6 h-6 text-gray-900", onClick: onClose, "data-testid": "close-toast-notification" }) })] }));
};
export default Content;
