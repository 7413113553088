import { jsx as _jsx } from "react/jsx-runtime";
import { faBell, faUser, faKey, faGlobe, } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { AccountSettingsTab } from './constants';
import messages from './messages';
import EnglishUS from '../../images/Languages/EnglishUS.png';
export const options = [
    {
        label: _jsx(FormattedMessage, Object.assign({}, messages.profile)),
        value: AccountSettingsTab.PROFILE,
        icon: _jsx(FontAwesomeIcon, { icon: faUser, className: "h-4 w-4" }),
    },
    {
        label: _jsx(FormattedMessage, Object.assign({}, messages.notification_preferences)),
        value: AccountSettingsTab.NOTIFICATION,
        icon: _jsx(FontAwesomeIcon, { icon: faBell, className: "h-4 w-4" }),
    },
    {
        label: _jsx(FormattedMessage, Object.assign({}, messages.change_password)),
        value: AccountSettingsTab.PASSWORD,
        icon: _jsx(FontAwesomeIcon, { icon: faKey, className: "h-4 w-4" }),
    },
    {
        label: _jsx(FormattedMessage, Object.assign({}, messages.language_settings)),
        value: AccountSettingsTab.LANGUAGE,
        icon: _jsx("img", { src: EnglishUS, alt: "US flag", className: "h-4 w-4" }),
    },
    {
        label: _jsx(FormattedMessage, Object.assign({}, messages.timezone)),
        value: AccountSettingsTab.TIMEZONE,
        icon: _jsx(FontAwesomeIcon, { icon: faGlobe, className: "h-4 w-4" }),
    },
];
