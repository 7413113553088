import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Bars3BottomLeftIcon } from '@heroicons/react/20/solid';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';
import { Button } from 'app/components/Base/Button';
import messages from './messages';
const SectionDetails = (props) => {
    const { id, name, region, description, handleEditProjectModal } = props;
    return (_jsx("div", { className: "w-full px-4 box-border", role: "region", "aria-label": `Project details for ${name}`, children: _jsxs("div", { className: "border-b border-gray-200 p-6 box-border", children: [_jsx("div", { children: _jsxs("div", { className: "flex", children: [_jsxs("div", { className: "w-4/5", children: [_jsx("p", { className: "text-lg font-medium text-gray-900", children: name }), _jsx("span", { className: "text-sm font-normal text-gray-500", children: id })] }), _jsx("div", { className: "flex justify-end w-1/5", children: _jsx(Button, { onClick: () => handleEditProjectModal(props.id), variant: "ghost", "aria-label": `Edit project ${name}`, children: _jsx(FormattedMessage, Object.assign({}, messages.edit_project)) }) })] }) }), _jsxs("div", { className: "mt-6 flex", children: [_jsxs("div", { className: "w-5/12 flex", children: [_jsxs("div", { className: "flex mr-8", children: [_jsx(Bars3BottomLeftIcon, { className: "h-5 w-5 text-gray-500", "aria-hidden": "true" }), _jsx("span", { className: "text-sm font-normal text-gray-500 ml-2", children: _jsx(FormattedMessage, Object.assign({}, messages.description)) })] }), _jsx("div", { className: "flex flex-col", children: _jsx("span", { className: "text-sm text-gray-900 font-normal items-start", children: description }) })] }), _jsxs("div", { className: "w-7/12 flex text-sm text-gray-500", children: [_jsxs("div", { className: "flex mr-8", children: [_jsx(MapPinIcon, { className: "h-5 w-5 text-gray-500", "aria-hidden": "true" }), _jsx("span", { className: "text-sm font-normal text-gray-500 ml-2", children: _jsx(FormattedMessage, Object.assign({}, messages.geography)) })] }), _jsx("div", { className: "flex flex-col", children: _jsx("span", { className: "text-sm text-gray-900 font-normal", children: region }) })] })] })] }) }));
};
export default SectionDetails;
