var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ApolloClient, InMemoryCache, split, HttpLink, } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist';
import localforage from 'localforage';
import { get } from 'lodash';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getHasuraHeaders } from '@utils/helpers';
import { getCurrentRole } from './data/roles';
export class Client {
    constructor() {
        if (!Client.instance) {
            Client.instance = this;
        }
    }
    static getInstance() {
        if (!Client.instance) {
            Client.instance = new Client();
        }
        return Client.instance;
    }
    get client() {
        if (!this._client) {
            throw new Error('Apollo Client not initialized. Call createClient first.');
        }
        return this._client;
    }
    get persistor() {
        if (!this._persistor) {
            throw new Error('Apollo Client persistor not initialized. Call createClient first.');
        }
        return this._persistor;
    }
    createClient(_a) {
        return __awaiter(this, arguments, void 0, function* ({ graphToken, errorCallback, }) {
            if (this._client)
                return this._client;
            const token = yield graphToken;
            const parsedHeaders = getHasuraHeaders(token);
            const roles = get(parsedHeaders, 'X-Hasura-Allowed-Roles');
            const headers = {
                Authorization: `Bearer ${token}`,
                'x-hasura-role': getCurrentRole(roles),
            };
            const httplinkUrl = `${process.env.HASURA_HTTP}/v1/graphql`;
            const httpLink = new HttpLink({
                uri: httplinkUrl,
                headers,
            });
            const errorLink = onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const err of graphQLErrors) {
                        switch (err.extensions.code) {
                            case 'invalid-jwt':
                            case 'start-failed':
                                if (errorCallback) {
                                    errorCallback();
                                }
                                else {
                                    window.location.href = '/logout';
                                }
                                break;
                            default:
                                if (console) {
                                    console.warn(`[GraphQL error]: Message: ${err.message}, Location: ${err.extensions.code}`);
                                }
                        }
                    }
                }
                if (networkError && console) {
                    console.error(`[Network error]: ${networkError}`);
                }
            });
            const wss = process.env.HASURA_WS;
            const wssClient = new SubscriptionClient(wss, {
                reconnect: true,
                connectionParams: {
                    headers,
                },
            });
            const wsLink = new WebSocketLink(wssClient);
            const link = split(({ query }) => {
                const { kind, operation } = getMainDefinition(query);
                return kind === 'OperationDefinition' && operation === 'subscription';
            }, wsLink, httpLink);
            const cache = new InMemoryCache();
            this._persistor = new CachePersistor({
                cache,
                maxSize: false,
                debounce: 500,
                storage: new LocalForageWrapper(localforage),
            });
            yield localforage.setDriver([
                localforage.INDEXEDDB,
                localforage.LOCALSTORAGE,
            ]);
            this._client = new ApolloClient({
                link: errorLink.concat(link),
                cache,
                name: 'proview-console-client',
                version: process.env.VERSION || 'dev',
            });
            return this._client;
        });
    }
    clearCache() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._persistor) {
                return this._persistor.purge();
            }
            return Promise.resolve();
        });
    }
    removeClient() {
        if (this._client) {
            this._client.stop();
            return this.client.clearStore();
        }
        return true;
    }
}
const instance = Client.getInstance();
export default instance;
