import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Header from '@components/Base/Header';
import SessionsList from '@components/Sessions/SessionsList';
import messages from '@containers/sessions/messages';
import { HeaderContext } from '@contexts/HeaderContext';
import { useAppSelector } from '@utils/hooks';
import GroupDropdown from 'app/components/Base/GroupDropdown';
const Sessions = () => {
    const { currentProject } = useAppSelector((state) => state.projects);
    const { handleProjectChange, options } = useContext(HeaderContext);
    return (_jsxs(_Fragment, { children: [_jsx(Header, { details: { label: _jsx(FormattedMessage, Object.assign({}, messages.sessions)) }, children: _jsx("div", { className: "w-[308px]", children: _jsx(GroupDropdown, { selectedValue: { label: currentProject.name }, options: options, onChange: handleProjectChange }) }) }), _jsx(SessionsList, { sessionData: [] })] }));
};
export default Sessions;
