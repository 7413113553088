import { jsx as _jsx } from "react/jsx-runtime";
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from '@containers/app/App';
import { LanguageProvider, useLanguage, } from '@contexts/LanguageContext';
import { store } from '@utils/store';
import './index.css';
import I18nProvider from './i18n';
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        environment: process.env.ENVIRONMENT || 'dev',
        tracesSampleRate: process.env.ENVIRONMENT === 'local' ? 1.0 : 0.1,
        release: process.env.SENTRY_RELEASE || 'proview-console-client@dev',
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}
const AppWrapper = () => {
    const { locale } = useLanguage();
    return (_jsx(I18nProvider, { locale: locale, children: _jsx(App, {}) }));
};
const rootElement = document.getElementById('root');
if (rootElement) {
    const root = createRoot(rootElement);
    root.render(_jsx(Provider, { store: store, children: _jsx(LanguageProvider, { children: _jsx(AppWrapper, {}) }) }));
}
else {
    console.error('Root element with id "root" not found in the document.');
}
