import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import messages, { generateChangeLogMessages, } from '@components/Flows/ConfigPanels/messages';
import { VERSION_LOG_KEY_VALUE_MAPTING } from '@utils/data/enums';
const ChangeLogList = ({ detail }) => {
    const renderBlacklistApps = (value, changeIndex, key) => {
        const parsedObject = JSON.parse(value);
        return Object.entries(parsedObject).map(([subKey, subValue]) => {
            const displayValue = Array.isArray(subValue) && subValue.length > 0
                ? subValue.join(', ')
                : 'N/A';
            return (_jsxs("li", { children: [_jsx(FormattedMessage, Object.assign({}, messages.blacklisted_apps_for)), " ", subKey, " :", ' ', displayValue] }, `${changeIndex}-${key}-${subKey}`));
        });
    };
    const renderChangeLogItem = (key, value, changeIndex) => {
        if (key === 'sb_blacklisted_apps' &&
            typeof value === 'string' &&
            value !== VERSION_LOG_KEY_VALUE_MAPTING.NOT_AVAILABLE) {
            return renderBlacklistApps(value, changeIndex, key);
        }
        return (_jsxs("li", { children: [_jsx(FormattedMessage, Object.assign({}, generateChangeLogMessages(key))), ' ', _jsx(FormattedMessage, Object.assign({}, generateChangeLogMessages(value)))] }, `${changeIndex}-${key}`));
    };
    return (_jsx("ul", { className: "text-sm text-gray-900 mt-5 leading-5 list-disc ml-4 font-roboto", children: detail.changeLogs.map((change, changeIndex) => Object.entries(change).map(([key, value]) => renderChangeLogItem(key, value, changeIndex))) }));
};
export default ChangeLogList;
