import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { classNames } from '@utils/classNames';
import { handleEnterKeyPress } from '@utils/helpers';
import messages from './messages';
const ConfigureCard = (props) => {
    const { isSelected, cardDetail, children, openConfig } = props;
    const handleOpenConfigClick = () => openConfig(cardDetail.type);
    const handleOpenConfigKeyPress = (e) => handleEnterKeyPress(e, () => openConfig(cardDetail.type));
    return (_jsxs("div", { "data-testid": "config_card", role: "button", className: classNames('rounded-lg shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)] w-full h-max overflow-hidden hover:border-2 hover:border-blue-700', isSelected ? 'border-2 border-blue-700' : 'border-2 border-transparent'), tabIndex: 0, onClick: handleOpenConfigClick, onKeyDown: handleOpenConfigKeyPress, children: [_jsxs("div", { className: "px-4 pt-4 pb-3 bg-gray-100 flex gap-5 h-[80px]", children: [_jsx("div", { className: "min-w-32 max-w-32 h-32", children: _jsx("img", { src: cardDetail.logo, alt: "Configure Card icon", className: "w-full h-full" }) }), _jsxs("div", { className: "flex justify-between items-start w-full pt-1", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "text-lg text-gray-900", children: cardDetail.label }), _jsx("span", { className: "text-sm text-gray-600", children: cardDetail.subText })] }), _jsx("span", { className: "text-sm text-blue-700 font-medium", children: _jsx(FormattedMessage, Object.assign({}, messages.configure)) })] })] }), _jsx("div", { className: "min-h-[80px] pl-[164px] pt-3 bg-white", children: children })] }));
};
export default ConfigureCard;
