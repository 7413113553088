import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import Select, { components, } from 'react-select';
export const reactSelectDefaultStyles = {
    input: (props) => (Object.assign(Object.assign({}, props), { input: {
            boxShadow: 'none !important',
            border: 'none !important',
        } })),
    placeholder: (props) => (Object.assign(Object.assign({}, props), { color: 'gray-400' })),
    control: (props) => (Object.assign(Object.assign({}, props), { boxShadow: 'none', fontSize: '0.875rem' })),
    option: (props, state) => (Object.assign(Object.assign({}, props), { fontSize: '0.875rem', backgroundColor: state.isSelected || state.isFocused ? 'gray-100' : 'white', color: 'gray-900' })),
    multiValue: (props) => (Object.assign(Object.assign({}, props), { backgroundColor: '#EFF6FF', borderRadius: '12px', margin: '2px', color: '#1D4ED8' })),
    multiValueLabel: () => ({
        color: '#1D4ED8',
        paddingLeft: '10px',
    }),
    multiValueRemove: (props) => (Object.assign(Object.assign({}, props), { display: 'flex', alignItems: 'center', justifyContent: 'center', ':hover': {
            backgroundColor: 'transparent',
            color: '#1D4ED8',
        } })),
};
const DropdownIndicator = (props) => {
    const color = 'text-gray-900';
    return (_jsx(components.DropdownIndicator, Object.assign({}, props, { children: _jsx(ChevronDownIcon, { className: `h-6 w-6 ${color}` }) })));
};
const DropdownSearchIndicator = (props) => {
    return (_jsx(components.DropdownIndicator, Object.assign({}, props, { children: _jsx(MagnifyingGlassIcon, { className: "w-5 h-5 text-gray-900" }) })));
};
const NoOptionsMessage = () => (_jsx("div", { className: "text-sm font-normal leading-[16px] mx-0 text-gray-900 p-2", children: "No results found" }));
export function Dropdown({ id, value, isDisabled, isLoading, isClearable = false, name = 'dropdown', options, onChange, placeholder, isSearchable = false, isMulti = false, customStyles = {}, controlShouldRenderValue = true, formatOptionLabel, isSearchIcon = false, }) {
    return (_jsx(Select, { inputId: id, value: value, onChange: onChange, isDisabled: isDisabled, isLoading: isLoading, isClearable: isClearable, isSearchable: isSearchable, name: name, placeholder: placeholder, options: options, styles: Object.assign(Object.assign({}, reactSelectDefaultStyles), customStyles), components: Object.assign(Object.assign({}, Select), { DropdownIndicator: isSearchIcon
                ? DropdownSearchIndicator
                : DropdownIndicator, NoOptionsMessage: NoOptionsMessage }), classNames: {
            control: () => classNames('border rounded !shadow-0 !bg-white !border-gray-200'),
            placeholder: () => 'text-sm font-normal leading-[16px] mx-0 text-gray-400',
            input: () => 'm-0 p-0',
            indicatorSeparator: () => 'hidden',
            indicatorsContainer: () => 'h-8',
            singleValue: () => 'text-sm font-normal leading-[16px] mx-0 text-gray-900',
            valueContainer: () => 'p-0',
            menuList: () => 'flex flex-col',
            option: ({ isSelected }) => classNames('px-1.5 py-2 text-sm', isSelected ? 'bg-gray-100' : 'hover:bg-gray-100 bg-white'),
        }, closeMenuOnSelect: !isMulti, isMulti: isMulti, controlShouldRenderValue: controlShouldRenderValue, menuPlacement: "auto", backspaceRemovesValue: false, formatOptionLabel: formatOptionLabel }));
}
export default memo(Dropdown);
