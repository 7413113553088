import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import notFound from '../../../images/svgs/notfound.svg';
const NotFound = () => {
    const intl = useIntl();
    return (_jsxs("div", { className: "fixed inset-0 z-10 flex flex-col items-center justify-center", children: [_jsx("div", { className: "w-[36rem] text-center text-gray-900 text-[32px] font-semibold", children: _jsx(FormattedMessage, Object.assign({}, messages.page_not_found)) }), _jsx("div", { className: "w-96 text-center text-gray-700 text-base font-normal leading-[27px]", children: _jsx(FormattedMessage, Object.assign({}, messages.page_not_found_sub_text)) }), _jsx("div", { className: "w-[23rem] h-[340px] my-4", children: _jsx("img", { src: notFound, alt: intl.formatMessage({
                        id: messages.page_not_found.defaultMessage,
                    }), className: "w-full h-full" }) })] }));
};
export default NotFound;
