import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable, } from '@tanstack/react-table';
import { get, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import AddFlowModal from '@components/Flows/AddFlowModal';
import FlowListTable from '@components/Flows/FlowListTable';
import { createColumns } from '@components/Flows/FlowListTable/columns';
import NoFlowsPage from '@components/Flows/NoFlowsPage';
import { formatUserList, getPageCount } from '@utils/helpers';
import { Button } from 'app/components/Base/Button';
import SearchInput from 'app/components/Base/Input';
import Pagination from 'app/components/Base/Pagination';
import messages from './messages';
import ListFilters from '../Filters';
export const updatedAtSortingFn = (rowA, rowB) => {
    const dateA = new Date(rowA.original.updated_at);
    const dateB = new Date(rowB.original.updated_at);
    return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
};
const FlowList = (props) => {
    const { searchValue, updateSearchValue, pagination, setPagination, flow, handleCreateFlow, isLoading, data, totalCount, templates, handleToggleAddFlowModal, handleResetFlowState, currentProject, openAddFlowModal, appliedFilters, updateAppliedFilters, userList, } = props;
    const navigate = useNavigate();
    const showEmptyPage = isEmpty(appliedFilters);
    const handleClick = (uuid) => {
        navigate(`/flows/${uuid}`);
    };
    const columns = useMemo(() => createColumns(updatedAtSortingFn, handleClick), [handleClick]);
    const pageCount = getPageCount(totalCount, pagination);
    const table = useReactTable({
        data,
        columns,
        pageCount,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        state: {
            pagination,
        },
        manualPagination: true,
    });
    const { previousPage, nextPage, getCanPreviousPage, getCanNextPage, getState, } = table;
    const addFlow = (_jsxs(_Fragment, { children: [_jsx(FontAwesomeIcon, { icon: faPlus }), _jsx(FormattedMessage, Object.assign({}, messages.add_flow))] }));
    const handleToggleModalOpen = () => {
        handleToggleAddFlowModal(!openAddFlowModal);
    };
    const isRenderNoFlowsPage = isEmpty(data) && !isLoading && !searchValue && showEmptyPage;
    const formattedUserList = formatUserList(userList.data);
    const selectedUserDetails = useMemo(() => {
        return formattedUserList.find((user) => get(user, 'id') === get(appliedFilters, 'updated_by'));
    }, [formattedUserList, get(appliedFilters, 'updated_by')]);
    return (_jsxs(_Fragment, { children: [isRenderNoFlowsPage ? (_jsx(NoFlowsPage, { handleToggleModalOpen: handleToggleModalOpen })) : (_jsxs("div", { className: "px-8 py-5", children: [_jsxs("div", { className: "flex justify-between", children: [_jsx(Button, { onClick: handleToggleModalOpen, children: addFlow }), _jsxs("div", { className: "flex items-center justify-end gap-2", children: [_jsx(SearchInput, { initialValue: searchValue, handleChange: updateSearchValue, placeholderMessageId: "app.components.flows.flow_list.search_flows" }), _jsx("div", { className: "pt-1", children: _jsx(ListFilters, { isLoading: userList.isLoading, selectedUserDetails: selectedUserDetails, formattedUserList: formattedUserList, appliedFilters: appliedFilters, updateAppliedFilters: updateAppliedFilters }) }), _jsx("div", { className: "w-0 h-6 border border-gray-300" }), _jsx(Pagination, { total: totalCount, pageSize: pagination.pageSize, currentPage: getState().pagination.pageIndex, summary: true, previousPage: previousPage, nextPage: nextPage, getCanPreviousPage: getCanPreviousPage, getCanNextPage: getCanNextPage })] })] }), _jsx(FlowListTable, { data: data, columns: columns, pagination: pagination, setPagination: setPagination, searchText: searchValue, isLoading: isLoading })] })), _jsx(AddFlowModal, { openAddFlowModal: openAddFlowModal, handleToggleAddFlowModal: handleToggleModalOpen, handleResetFlowState: handleResetFlowState, templates: templates, flow: flow, currentProject: currentProject, handleCreateFlow: handleCreateFlow })] }));
};
export default FlowList;
