import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { find, get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import { Dropdown } from '@components/Base/Dropdown';
import { LOCAL_STORAGE_KEYS } from '@utils/data/enums';
import { setItemInLocalStorage } from '@utils/helpers';
import { languages } from './languages';
import messages from './messages';
const LanguageSelectContent = ({ selectedLanguage, handleSetLocale, }) => {
    const [language, setLanguage] = useState(() => find(languages, ['value', selectedLanguage]));
    const save = () => {
        setItemInLocalStorage(LOCAL_STORAGE_KEYS.LANGUAGE, JSON.stringify(language));
        if (get(language, 'value')) {
            handleSetLocale(get(language, 'value', ''));
        }
    };
    const formatOptionLabel = (option) => (_jsxs("div", { className: "flex items-center", children: [_jsx("span", { children: _jsx("img", { src: get(option, 'flag'), alt: "flag", className: "mr-2 h-7 w-7" }) }), _jsx("span", { children: get(option, 'label') })] }));
    return (_jsxs("div", { className: "w-full", children: [_jsx("div", { className: "mb-4 text-sm font-normal leading-4 text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.change_lang)) }), _jsx("div", { className: "w-[470px]", children: _jsx(Dropdown, { value: language, name: "provider-select", options: languages, onChange: (value) => setLanguage(value), placeholder: "Change Language", id: "topics", isSearchable: false, formatOptionLabel: formatOptionLabel }) }), _jsx("div", { className: "flex justify-end gap-3 pt-10 pr-2", children: _jsx(Button, { disabled: (language === null || language === void 0 ? void 0 : language.value) === selectedLanguage, onClick: save, children: _jsx(FormattedMessage, Object.assign({}, messages.submit)) }) })] }));
};
export default LanguageSelectContent;
