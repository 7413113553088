import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { faLoader } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { triggerToast } from '@components/Base/Notification';
import EditFlowNameModal from '@components/Flows/EditFlowNameModal';
import FlowDetailsComponent from '@components/Flows/FlowDetails';
import FlowHeader from '@components/Flows/FlowHeader';
import { getFlowConfigByIDAction, updateFlowAction, updateFlowConfigAction, createFlowConfigAction, resetTriggerToast, } from '@containers/flowDetails/slice';
import { HeaderContext } from '@contexts/HeaderContext';
import { STATUS, TOAST_VARIANT, TOAST_STATE } from '@utils/data/enums';
import { getMixpanelProperties } from '@utils/helpers';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
import { proviewUserFlowDetailPageViewedEvent } from '@utils/mixpanelActions';
import messages from './messages';
const FlowDetails = () => {
    var _a, _b;
    const dispatch = useAppDispatch();
    const { flowId } = useParams();
    const { data, isLoading, updateFlowStatus, updateFlowConfigStatus, createFlowConfigStatus, versionLog, triggerToast: triggerFlowConfigToast, } = useAppSelector((state) => state.flowConfig);
    const currentProject = useAppSelector((state) => state.projects.currentProject);
    const { user, isMixpanelInitialized } = useAppSelector((state) => state.app);
    const [editFlowNameState, setEditFlowNameState] = useState(false);
    const [flowConfigChanges, setFlowConfigChanges] = useState(null);
    const { handleProjectChange, options } = useContext(HeaderContext);
    useEffect(() => {
        if (data) {
            if (!isEqual(data.flow_configs[0], flowConfigChanges)) {
                setFlowConfigChanges(data.flow_configs[0]);
            }
        }
    }, [data && data.flow_configs[0]]);
    useEffect(() => {
        if (triggerFlowConfigToast &&
            triggerFlowConfigToast !== TOAST_STATE.UPDATE_FLOW_NAME_INITIATED) {
            handleTriggerToast(triggerFlowConfigToast);
            dispatch(resetTriggerToast(null));
        }
    }, [triggerFlowConfigToast]);
    useEffect(() => {
        if (flowId)
            dispatch(getFlowConfigByIDAction({ uuid: flowId }));
    }, [currentProject.id, flowId]);
    useEffect(() => {
        if (isMixpanelInitialized && user) {
            const mixpanelProperties = getMixpanelProperties(user);
            proviewUserFlowDetailPageViewedEvent(mixpanelProperties);
        }
    }, [isMixpanelInitialized, user.tenant]);
    const showToast = (variant, summary, title) => {
        triggerToast({
            variant,
            message: {
                title: title && _jsx(FormattedMessage, Object.assign({}, title)),
                summary: _jsx(FormattedMessage, Object.assign({}, summary)),
            },
        });
    };
    const handleTriggerToast = (triggerFlowConfigToast) => {
        switch (triggerFlowConfigToast) {
            case TOAST_STATE.FLOW_CONFIG_CHANGES_SAVED:
                showToast(TOAST_VARIANT.SUCCESS, messages.changes_saved_description, messages.changes_saved);
                break;
            case TOAST_STATE.FLOW_CONFIG_CHANGES_FAILED:
                showToast(TOAST_VARIANT.DANGER, messages.changes_failed_description, messages.changes_failed);
                break;
            case TOAST_STATE.UPDATE_FLOW_NAME_SUCCESS:
                showToast(TOAST_VARIANT.SUCCESS, messages.flow_name_updated_description, messages.flow_name_updated);
                break;
            case TOAST_STATE.UPDATE_FLOW_NAME_FAILED:
                showToast(TOAST_VARIANT.DANGER, messages.flow_name_update_failed_description, messages.flow_name_update_failed);
                break;
            case TOAST_STATE.PUBLISH_FLOW:
                showToast(TOAST_VARIANT.SUCCESS, messages.flow_publish_description, messages.flow_publish);
                break;
            default:
                showToast(TOAST_VARIANT.DANGER, messages.something_went_wrong);
        }
    };
    const updateFlowConfig = (payload) => {
        const blacklistedAppsKey = 'sb_blacklisted_apps';
        const { status } = versionLog[0];
        const updatedPayload = Object.assign({}, payload);
        delete updatedPayload.user_by_created_by;
        const getActionPayload = () => {
            switch (status) {
                case STATUS.DRAFT:
                    return {
                        payload: Object.assign(Object.assign({}, updatedPayload), { id: data === null || data === void 0 ? void 0 : data.flow_configs[data.flow_configs.length - 1].id }),
                        flowUuid: flowId,
                    };
                case STATUS.PUBLISHED:
                    delete updatedPayload.id;
                    return {
                        payload: Object.assign(Object.assign({}, updatedPayload), { flow_id: data === null || data === void 0 ? void 0 : data.id }),
                        flowUuid: flowId,
                    };
                default:
            }
        };
        const actionPayload = getActionPayload();
        if (actionPayload && blacklistedAppsKey in actionPayload.payload) {
            const blacklistedApps = actionPayload.payload[blacklistedAppsKey];
            if (typeof blacklistedApps === 'string') {
                try {
                    actionPayload.payload[blacklistedAppsKey] =
                        JSON.parse(blacklistedApps);
                }
                catch (e) {
                    actionPayload.payload[blacklistedAppsKey] = {};
                }
            }
            if (blacklistedApps === null) {
                actionPayload.payload[blacklistedAppsKey] = {};
            }
        }
        if (status === STATUS.DRAFT) {
            dispatch(updateFlowConfigAction(actionPayload));
        }
        else {
            dispatch(createFlowConfigAction(actionPayload));
        }
    };
    const updateFlow = (payload) => {
        if (data) {
            dispatch(updateFlowAction(Object.assign(Object.assign({}, payload), { id: data.id })));
            'name' in payload &&
                dispatch(resetTriggerToast(TOAST_STATE.UPDATE_FLOW_NAME_INITIATED));
        }
    };
    const handleUpdateConfigChanges = (payload) => {
        setFlowConfigChanges(Object.assign(Object.assign({}, flowConfigChanges), payload));
    };
    const handlePublishFlow = () => {
        updateFlowConfig({ status: STATUS.PUBLISHED });
    };
    const handleSetDefault = () => {
        updateFlow({ is_default: true });
    };
    return (_jsxs("div", { children: [_jsx(FlowHeader, { options: options, selectedFlow: {
                    name: get(data, 'name', ''),
                    external_id: get(data, 'uuid'),
                    status: (_a = versionLog[0]) === null || _a === void 0 ? void 0 : _a.status,
                    is_default: get(data, 'is_default', false),
                }, selectedProject: {
                    label: currentProject.name,
                }, currentProject: currentProject, handleProjectChange: handleProjectChange, openUpdateNameModal: () => setEditFlowNameState(true), setAsDefault: handleSetDefault, updateFlowState: updateFlowStatus, isPublishedLoading: updateFlowConfigStatus.isPublishedLoading, publishFlow: handlePublishFlow, isFlowChanged: !!data && !isEqual(flowConfigChanges, data.flow_configs[0]) }), isLoading ? (_jsx("div", { className: "w-full h-[calc(100vh-81px)] flex justify-center items-center", "data-testid": "loading-spinner", children: _jsx(FontAwesomeIcon, { spin: true, icon: faLoader, className: "w-8 h-8" }) })) : (_jsx(FlowDetailsComponent, { versionLog: versionLog, updatedConfig: flowConfigChanges, config: (_b = (data && data.flow_configs[0])) !== null && _b !== void 0 ? _b : null, updateConfig: handleUpdateConfigChanges, updateFlowConfigStatus: updateFlowConfigStatus, createFlowConfigStatus: createFlowConfigStatus, saveConfig: () => updateFlowConfig(flowConfigChanges) })), _jsx(EditFlowNameModal, { name: get(data, 'name', ''), isModalOpen: editFlowNameState, closeModal: () => setEditFlowNameState(false), updateFlowName: updateFlow, updateFlowStatus: updateFlowStatus })] }));
};
export default FlowDetails;
