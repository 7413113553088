import { catchError } from './helpers';
export const fileTypes = [
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/webp',
];
export function validFileType(file, supportedFileTypes = fileTypes) {
    return supportedFileTypes.includes(file.type);
}
export const readFile = ({ file, callback, supportedFileTypes, ignoreConversion, }) => {
    const reader = new FileReader();
    reader.onload = () => {
        callback.onSuccess(reader.result);
    };
    if (file) {
        if (validFileType(file, supportedFileTypes)) {
            if (ignoreConversion) {
                callback.onSuccess(file);
            }
            else {
                reader.readAsDataURL(file);
            }
        }
        else {
            callback.onError(Error(`File not supported.`));
        }
    }
};
export const handleImageUpload = ({ event, callback, supportedFileTypes, ignoreConversion = false, }) => {
    try {
        const files = event.target.files ? Array.from(event.target.files) : [];
        if (files && files.length) {
            files.forEach((file) => readFile({ file, callback, supportedFileTypes, ignoreConversion }));
        }
        else {
            callback.onError(Error(`No File selected.`));
        }
        event.target.value = '';
    }
    catch (error) {
        callback.onError(error);
        catchError(error, false);
    }
};
