import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useMemo } from 'react';
import { handleProjectFormOpen } from '@containers/projects/helpers';
import { setCurrentProject } from '@containers/projects/slice';
import { AddNewProject, } from '@containers/projects/types';
import { transformDataToOptions } from '@utils/helpers';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
import { proviewUserNewProjectCreationStartedEvent } from 'app/utils/mixpanelActions';
export const initialHeaderContext = {
    handleProjectChange: (selectedOption) => selectedOption,
    options: [],
};
export const HeaderContext = createContext(initialHeaderContext);
const HeaderProvider = ({ children }) => {
    const dispatch = useAppDispatch();
    const { data } = useAppSelector((state) => state.projects);
    const { user } = useAppSelector((state) => state.app);
    const handleProjectChange = (selectedOption) => {
        if (selectedOption.value !== AddNewProject) {
            const currentProject = data.find((option) => option.id === selectedOption.details.id);
            currentProject && dispatch(setCurrentProject(currentProject));
        }
        else {
            handleProjectFormOpen();
            const mixpanelProperties = {
                orgId: user.tenant.id,
                orgName: user.tenant.name,
                userId: user.id,
                userRole: user.currentRole,
            };
            proviewUserNewProjectCreationStartedEvent(mixpanelProperties);
        }
        return selectedOption;
    };
    const options = useMemo(() => transformDataToOptions(data), [data]);
    return (_jsx(HeaderContext.Provider, { value: { handleProjectChange, options }, children: children }));
};
export default HeaderProvider;
