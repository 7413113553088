import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '@components/Base/Header';
import { triggerToast } from '@components/Base/Notification';
import FlowList from '@components/Flows/FlowList';
import messages from '@containers/flowList/messages';
import { getUserListAction } from '@containers/user/slice';
import { HeaderContext } from '@contexts/HeaderContext';
import { DUPLICATE_FLOW_ERROR, TOAST_VARIANT } from '@utils/data/enums';
import { getMixpanelProperties } from '@utils/helpers';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
import { provieUserNewFlowCreatedEvent, proviewUserNewFlowCreationStartedEvent, proviewUserFlowPageOpenedEvent, } from '@utils/mixpanelActions';
import GroupDropdown from 'app/components/Base/GroupDropdown';
import { getFlowListAction, getTemplateListAction, createFlowAction, setUpdateAddFlowModal, setListTableState, resetFlowState, resetTriggerFlowCreationToast, } from './slice';
export const getCurrentFlowType = (createFlowPayload) => {
    return createFlowPayload.is_template
        ? 'template'
        : createFlowPayload.reference_flow_id
            ? 'duplicate'
            : 'new';
};
const Flows = () => {
    var _a, _b, _c, _d, _e;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { isLoading, data, totalCount, flow, templateList: { data: templates }, openAddFlowModal, tableState, triggerFlowCreationToast, } = useAppSelector((state) => state.flows);
    const userList = useAppSelector((state) => state.userList);
    const { user, isMixpanelInitialized } = useAppSelector((state) => state.app);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [searchValue, updateSearchValue] = useState((_b = (_a = tableState.searchText) !== null && _a !== void 0 ? _a : searchParams.get('search')) !== null && _b !== void 0 ? _b : '');
    const [currentFlowType, setCurrentFlowType] = useState('');
    const [appliedFilters, updateAppliedFilters] = useState((_e = (_c = tableState.filter) !== null && _c !== void 0 ? _c : JSON.parse((_d = searchParams.get('filter')) !== null && _d !== void 0 ? _d : '{}')) !== null && _e !== void 0 ? _e : {});
    const { currentProject } = useAppSelector((state) => state.projects);
    const { handleProjectChange, options } = useContext(HeaderContext);
    useEffect(() => {
        dispatch(getUserListAction());
    }, [dispatch]);
    const updateURLParams = () => {
        setSearchParams((params) => {
            const setOrDeleteParam = (key, value) => {
                if (value) {
                    params.set(key, value);
                }
                else {
                    params.delete(key);
                }
            };
            setOrDeleteParam('search', searchValue);
            setOrDeleteParam('filter', Object.keys(appliedFilters).length > 0
                ? JSON.stringify(appliedFilters)
                : '');
            return params;
        });
    };
    useEffect(() => {
        updateURLParams();
    }, [searchValue, appliedFilters]);
    useEffect(() => {
        if (get(currentProject, 'id')) {
            const searchFilter = {
                searchExpression: searchValue
                    ? [
                        { name: { _ilike: `%${searchValue}%` } },
                        ...(!isNaN(+searchValue) ? [{ id: { _eq: searchValue } }] : []),
                    ]
                    : {},
            };
            const statusFilter = get(appliedFilters, 'status') || [];
            const filterConditions = {
                conditions: Object.assign(Object.assign({}, (statusFilter.length > 0
                    ? {
                        status: {
                            _in: statusFilter,
                        },
                    }
                    : {})), (Number.isInteger(get(appliedFilters, 'updated_by'))
                    ? { updated_by: { _in: get(appliedFilters, 'updated_by') } }
                    : {})),
            };
            dispatch(getFlowListAction(Object.assign(Object.assign(Object.assign({}, searchFilter), filterConditions), { projectId: get(currentProject, 'id'), limit: tableState.pageSize, offset: tableState.pageIndex * tableState.pageSize })));
        }
    }, [
        tableState,
        searchValue,
        appliedFilters,
        currentProject,
        navigate,
        dispatch,
    ]);
    useEffect(() => {
        if (get(currentProject, 'id')) {
            dispatch(setListTableState({
                tableState: {
                    pageIndex: pagination.pageIndex,
                    pageSize: pagination.pageSize,
                    searchText: searchValue,
                    filter: appliedFilters,
                },
                projectId: get(currentProject, 'id'),
            }));
        }
    }, [dispatch, pagination.pageIndex, pagination.pageSize, currentProject]);
    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: 10,
        });
        dispatch(setListTableState({
            tableState: {
                pageIndex: 0,
                pageSize: 10,
                searchText: searchValue,
                filter: appliedFilters,
            },
            projectId: get(currentProject, 'id'),
        }));
    }, [searchValue, appliedFilters]);
    useEffect(() => {
        if (openAddFlowModal) {
            !templates.length && dispatch(getTemplateListAction());
            const mixpanelProperties = getMixpanelProperties(user, currentProject.name);
            isMixpanelInitialized &&
                proviewUserNewFlowCreationStartedEvent(mixpanelProperties);
        }
    }, [openAddFlowModal, isMixpanelInitialized]);
    useEffect(() => {
        if (flow.error && flow.error !== DUPLICATE_FLOW_ERROR) {
            triggerToast({
                variant: TOAST_VARIANT.DANGER,
                message: {
                    title: _jsx(FormattedMessage, Object.assign({}, messages.flow_creation_failed)),
                    summary: _jsx(FormattedMessage, Object.assign({}, messages.flow_could_not_be_created)),
                },
            });
        }
    }, [flow.error]);
    useEffect(() => {
        if (triggerFlowCreationToast && flow.uuid) {
            const mixpanelProperties = getMixpanelProperties(user, currentProject.name, currentFlowType);
            isMixpanelInitialized &&
                provieUserNewFlowCreatedEvent(mixpanelProperties);
            triggerToast({
                variant: TOAST_VARIANT.SUCCESS,
                message: {
                    title: _jsx(FormattedMessage, Object.assign({}, messages.flow_created)),
                    summary: _jsx(FormattedMessage, Object.assign({}, messages.flow_created_description)),
                },
            });
            navigate(`${flow.uuid}`);
            dispatch(resetTriggerFlowCreationToast());
            dispatch(setUpdateAddFlowModal(!openAddFlowModal));
        }
    }, [flow.uuid, triggerFlowCreationToast, isMixpanelInitialized]);
    useEffect(() => {
        if (isMixpanelInitialized) {
            const mixpanelProperties = getMixpanelProperties(user, currentProject.name);
            proviewUserFlowPageOpenedEvent(mixpanelProperties);
        }
    }, [isMixpanelInitialized]);
    useEffect(() => {
        if (currentProject.token) {
            navigate(`/flows?project_uuid=${currentProject.token}`);
        }
    }, [currentProject.token]);
    const handleResetFlowState = () => {
        dispatch(resetFlowState());
    };
    const handleToggleAddFlowModal = () => {
        if (openAddFlowModal && flow.error) {
            handleResetFlowState();
        }
        dispatch(setUpdateAddFlowModal(!openAddFlowModal));
    };
    const handleCreateFlow = (createFlowPayload) => {
        const flowType = getCurrentFlowType(createFlowPayload);
        dispatch(createFlowAction(createFlowPayload));
        setCurrentFlowType(flowType);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Header, { details: { label: _jsx(FormattedMessage, Object.assign({}, messages.flows)) }, children: _jsx("div", { className: "w-[308px]", children: _jsx(GroupDropdown, { selectedValue: { label: currentProject.name }, options: options, onChange: handleProjectChange }) }) }), _jsx(FlowList, { userList: userList, appliedFilters: appliedFilters, updateAppliedFilters: updateAppliedFilters, searchValue: searchValue, updateSearchValue: updateSearchValue, setPagination: setPagination, pagination: pagination, handleToggleAddFlowModal: handleToggleAddFlowModal, templates: templates, flow: flow, currentProject: currentProject, handleCreateFlow: handleCreateFlow, isLoading: isLoading, data: data, totalCount: totalCount, openAddFlowModal: openAddFlowModal, handleResetFlowState: handleResetFlowState })] }));
};
export default Flows;
