import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    isLoading: false,
    error: null,
    data: [],
    totalCount: -1,
    limit: 10,
    offset: 0,
    flow: {
        isLoading: false,
        error: '',
        uuid: '',
    },
    conditions: {},
    searchExpression: {},
    projectId: null,
    templateList: {
        isLoading: false,
        error: '',
        data: [],
    },
    openAddFlowModal: false,
    tableState: {},
    triggerFlowCreationToast: false,
};
export const flowListSlice = createSlice({
    name: 'flowList',
    initialState: initialState,
    reducers: {
        createFlowAction: (state, _action) => {
            state.flow.isLoading = true;
            state.flow.error = '';
        },
        createFlowSuccessAction: (state, { payload }) => {
            state.flow.isLoading = false;
            state.flow.error = '';
            state.flow.uuid = payload.uuid;
            state.triggerFlowCreationToast = true;
        },
        createFlowErrorAction: (state, { payload: error }) => {
            state.flow.isLoading = false;
            state.flow.error = error;
            state.flow.uuid = '';
        },
        getFlowListAction: (state, action) => {
            state.isLoading = true;
            state.error = null;
            state.limit = action.payload.limit;
            state.offset = action.payload.offset;
            state.searchExpression = action.payload.searchExpression;
            state.projectId = action.payload.projectId;
            state.conditions = action.payload.conditions;
        },
        getFlowListSuccessAction: (state, { payload }) => {
            state.isLoading = false;
            state.data = payload.data;
            state.totalCount = payload.totalCount;
        },
        getFlowListErrorAction: (state, { payload: error }) => {
            state.isLoading = false;
            state.error = error;
        },
        setListTableState: (state, action) => {
            state.tableState = action.payload.tableState;
            state.projectId = action.payload.projectId;
        },
        getTemplateListAction: (state) => {
            state.templateList.isLoading = true;
            state.templateList.error = '';
        },
        getTemplateListSuccessAction: (state, { payload }) => {
            state.templateList.isLoading = false;
            state.templateList.data = payload;
            state.templateList.error = '';
        },
        getTemplateListErrorAction: (state, { payload: error }) => {
            state.templateList.isLoading = false;
            state.templateList.error = error;
        },
        setUpdateAddFlowModal: (state, action) => {
            state.openAddFlowModal = action.payload;
        },
        resetFlowState: (state) => {
            state.flow.isLoading = false;
            state.flow.error = '';
            state.flow.uuid = '';
        },
        resetTriggerFlowCreationToast: (state) => {
            state.triggerFlowCreationToast = false;
        },
    },
});
export const { getFlowListAction, getFlowListSuccessAction, getFlowListErrorAction, createFlowAction, createFlowSuccessAction, createFlowErrorAction, setListTableState, getTemplateListAction, getTemplateListSuccessAction, getTemplateListErrorAction, setUpdateAddFlowModal, resetFlowState, resetTriggerFlowCreationToast, } = flowListSlice.actions;
export default flowListSlice.reducer;
