// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhoneInputInput {
  border-radius: 6px !important;
  border: 1px solid rgb(208, 203, 203) !important;
  height: 38px;
}

.PhoneInputCountry {
  border: 1px solid rgb(208, 203, 203);
  padding: 0px 15px 0px 5px;
  border-radius: 6px;
}

.PhoneInputCountrySelectArrow {
  margin-left: 15px;
}
`, "",{"version":3,"sources":["webpack://./app/components/PhoneNumberUpdate/styles.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,+CAA+C;EAC/C,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".PhoneInputInput {\n  border-radius: 6px !important;\n  border: 1px solid rgb(208, 203, 203) !important;\n  height: 38px;\n}\n\n.PhoneInputCountry {\n  border: 1px solid rgb(208, 203, 203);\n  padding: 0px 15px 0px 5px;\n  border-radius: 6px;\n}\n\n.PhoneInputCountrySelectArrow {\n  margin-left: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
