import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
const Badge = ({ type, bgColor, textColor, rounded = 'full', children, }) => {
    const typeStyles = {
        primary: {
            bgColor: 'bg-blue-100',
            textColor: 'text-blue-700',
        },
        secondary: {
            bgColor: 'bg-blue-50',
            textColor: 'text-blue-700',
        },
        draft: {
            bgColor: 'bg-yellow-100',
            textColor: 'text-amber-800',
        },
    };
    const roundedClasses = {
        full: 'rounded-full',
        lg: 'rounded-lg',
        md: 'rounded-md',
        sm: 'rounded-sm',
        none: 'rounded-none',
    };
    const styles = type
        ? typeStyles[type]
        : {};
    return (_jsx("span", { className: classNames('inline-flex text-xs font-medium justify-center items-center py-1 px-3', bgColor || styles.bgColor, textColor || styles.textColor, roundedClasses[rounded]), children: children }));
};
export default Badge;
